// NAR BAR
export const SET_OPEN = "SET_OPEN";

// AUTH
export const GET_USER = "GET_USER";
export const ADD_TO_NEWSLETTER = "ADD_TO_NEWSLETTER";
export const SET_IS_LOADING = "SET_IS_LOADING";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const SET_ACCESS_TOKEN = "SET_ACCESS_TOKEN";

// TRANSACTION
export const CREATE_TRANSACTION = "CREATE_TRANSACTION";
export const DELETE_TRANSACTIONS = "DELETE_TRANSACTIONS";
export const CLEAR_TRANSACTIONS = "CLEAR_TRANSACTIONS";
export const REMOVE_TRANSACTION_DOCUMENT_ID = "REMOVE_TRANSACTION_DOCUMENT_ID";
export const UPDATE_TRANSACTION_PROPERTY = "UPDATE_TRANSACTION_PROPERTY";
export const FETCH_TRANSACTIONS = "FETCH_TRANSACTIONS";
export const FETCH_TRANSACTION = "FETCH_TRANSACTION";
export const SET_TRANSACTION = "SET_TRANSACTION";
export const SET_TRANSACTIONS = "SET_TRANSACTIONS";
export const UPDATE_TRANSACTION_DOCUMENTS_IDS = "UPDATE_TRANSACTION_DOCUMENTS_IDS";
export const SET_TRANSACTION_ID = "SET_TRANSACTION_ID";

// EXPLORE
export const EXPLORE = "EXPLORE";
export const CLEAR_EXPLORED_DATA = "CLEAR_EXPLORED_DATA";
export const SET_FILTRED_EXPLORED_DATA = "SET_FILTRED_EXPLORED_DATA";

// KEYWORD
export const CREATE_KEYWORD = "CREATE_KEYWORD";
export const FETCH_KEYWORDS = "FETCH_KEYWORDS";
export const FETCH_KEYWORD = "FETCH_KEYWORD";
export const UPDATE_KEYWORD = "UPDATE_KEYWORD";
export const DELETE_KEYWORD = "DELETE_KEYWORD";
export const GET_SYNCHRONIZED = "GET_SYNCHRONIZED";
export const START_SYNCHRONIZE = "START_SYNCHRONIZE";
export const SET_KEYWORD_SYNCHRONIZED = "SET_KEYWORD_SYNCHRONIZED";

// DOCUMENTS
export const UPDATE_TRANSACTION_DOCUMENT_UPLOADED = "UPDATE_TRANSACTION_DOCUMENT_UPLOADED";
export const SET_PDF_LOADING_TASK = "SET_PDF_LOADING_TASK";
export const SET_CITATION_HIGHLIGHT = "SET_CITATION_HIGHLIGHT";
export const SET_ACTIVE_PDF = "SET_ACTIVE_PDF";
export const UPLOAD_DOCUMENTS = "UPLOAD_DOCUMENTS";
export const FETCH_DOCUMENTS = "FETCH_DOCUMENTS";
export const FETCH_DOCUMENT = "FETCH_DOCUMENT";
export const REMOVE_DOCUMENT_FROM_DOCUMENTS = "REMOVE_DOCUMENT_FROM_DOCUMENTS";
export const CLEAR_DOCUMENTS = "CLEAR_DOCUMENTS";
export const SET_DOCUMENTS_TRANSACTION_ID = "SET_DOCUMENTS_TRANSACTION_ID";
export const SET_DOCUMENTS = "SET_DOCUMENTS";
export const UPDATE_DOCUMENT_PROPERTY = "UPDATE_DOCUMENT_PROPERTY";
export const DELETE_DOCUMENT_FROM_TRANSACTION = "DELETE_DOCUMENT_FROM_TRANSACTION";
export const ADD_DOCUMENT_TO_DOCUMENTS = "ADD_DOCUMENT_TO_DOCUMENTS";
export const ADD_DOCUMENT_TO_DOCUMENTS_WITH_TRANSACTIONS = "ADD_DOCUMENT_TO_DOCUMENTS_WITH_TRANSACTIONS";

// CONVERSATIONS
export const UPDATE_MESSAGE_LIKE = "UPDATE_MESSAGE_LIKE";
export const CREATE_CONVERSATION = "CREATE_CONVERSATION";
export const DELETE_CONVERSATIONS = "DELETE_CONVERSATIONS";
export const SET_MESSAGES = "SET_MESSAGES";
export const FETCH_CONVERSATION = "FETCH_CONVERSATION";
export const FETCH_CONVERSATIONS = "FETCH_CONVERSATIONS";

// SNACKBARS
export const SHOW_SNACKBAR = 'SHOW_SNACKBAR';
export const HIDE_SNACKBAR = 'HIDE_SNACKBAR';

// KG
export const PROCESS_DOC_FOR_KG = 'PROCESS_DOC_FOR_KG';
export const QUERY_KG = 'QUERY_KG';

// HYPOTHESIS
export const EXTRACT_ONE_HYPOTHESIS = 'EXTRACT_ONE_HYPOTHESIS';
export const EXTRACT_HYPOTHESIS = 'EXTRACT_HYPOTHESIS';
export const ADD_KEY_HYPOTHESIS = 'ADD_KEY_HYPOTHESIS';
export const CLEAR_HYPOTHESIS = 'CLEAR_HYPOTHESIS';
export const FETCH_HYPOTHESIS = 'FETCH_HYPOTHESIS';
export const DELETE_HYPOTHESIS = 'DELETE_HYPOTHESIS';