import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Box, CircularProgress, Grid, useMediaQuery, useTheme } from "@mui/material";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { convertCountryFormat, isMobile, windowHeight, windowWidth } from "../../../utils/utils";
import { ResponsivePie } from "@nivo/pie";
import { ResponsiveLine } from "@nivo/line";
import { colors } from "../../../utils/colors";
import { backendClient } from "../../../api/backend";
import { useSelector } from "react-redux";

const Analysis = () => {
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.up("sm"));

  const cardStyle = {
    borderRadius: 2,
    maxHeight: windowHeight / 6,
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
  };
  const graphStyle = {
    borderRadius: 2,
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
    // margin: theme.spacing(2),
    maxWidth: "100%",
    // width: windowWidth / 6,
    flexBasis: "calc(50% - 16px)",
  };

  const titleStyle = {
    fontSize: "16px",
    color: colors.GRAY_ONE,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };

  const valueStyle = {
    fontSize: "28px",
    color: colors.BLACK,
    fontWeight: "semibold",
  };

  const percentageStyle = {
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
    color: colors.GREEN,
    "& svg": {
      fontSize: "1rem",
      marginRight: "4px",
    },
  };

  const barChartStyle = {
    height: "20px",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    marginTop: windowHeight / 120,
    backgroundColor: "",
  };

  const barStyle = (value, index, data) => ({
    width: "6px",
    height: `${value}%`,
    backgroundColor: index === data.length - 1 ? colors.GREEN : colors.GRAY_TWO,
    borderRadius: "3px",
    marginRight: "10px",
  });

  // Dummy data for the bar chart
  const dealsData = [30, 60, 40, 90, 80, 100];
  const documentsData = [15, 40, 70, 91, 88, 100];
  const conversationsData = [20, 25, 60, 75, 80, 110];

  const cashFlowForecastData = [
    {
      id: "2018 Report",
      color: "hsl(220, 70%, 50%)",
      data: [
        { x: "Jan", y: 40 },
        { x: "Feb", y: 30 },
        { x: "Mar", y: 25 },
        { x: "Apr", y: 45 },
        { x: "May", y: 50 },
        { x: "Jun", y: 35 },
        { x: "Jul", y: 40 },
        { x: "Aug", y: 55 },
        { x: "Sep", y: 50 },
        { x: "Oct", y: 60 },
        { x: "Nov", y: 35 },
        { x: "Dec", y: 40 },
      ],
    },
    {
      id: "2019 Report",
      color: "hsl(115, 70%, 40%)",
      data: [
        { x: "Jan", y: 25 },
        { x: "Feb", y: 20 },
        { x: "Mar", y: 30 },
        { x: "Apr", y: 35 },
        { x: "May", y: 40 },
        { x: "Jun", y: 45 },
        { x: "Jul", y: 50 },
        { x: "Aug", y: 55 },
        { x: "Sep", y: 60 },
        { x: "Oct", y: 65 },
        { x: "Nov", y: 70 },
        { x: "Dec", y: 75 },
      ],
    },
  ];

  const [isLoadingDeals, setIsLoadingDeals] = useState(true);
  const [isLoadingDocuments, setIsLoadingDocuments] = useState(true);
  const [isLoadingConversations, setIsLoadingConversations] = useState(true);
  const [isLoadingCountryData, setIsLoadingCountryData] = useState(true);

  const [totalDeals, setTotalDeals] = useState(0);
  const [totalDocuments, setTotalDocuments] = useState(0);
  const [totalConversations, setTotalConversations] = useState(0);
  const [countryData, setCountryData] = useState([]);
  const [industryCount, setIndustryCount] = useState([]);

  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    const fetch_fn = async () => {
      const totalDeals_ = await backendClient.countTransactions();
      setTotalDeals(totalDeals_["count"]);
      setIsLoadingDeals(false);

      const totalDocuments_ = await backendClient.countDocuments();
      setTotalDocuments(totalDocuments_["count"]);
      setIsLoadingDocuments(false);

      const totalConversations_ = await backendClient.countConversations();
      setTotalConversations(totalConversations_["count"]);
      setIsLoadingConversations(false);

      const countryCount_ = await backendClient.valueCountTransaction("country");
      setCountryData(convertCountryFormat(countryCount_));
      setIsLoadingCountryData(false);
    };
    
    fetch_fn();
  }, [user]);

  // <SummaryCard title={"Total Deals"} trend={"+2%"} data={dealsData}/>
  const SummaryCard = ({ title, value, trend, data, isLoading }) => {
    return (
      <Card sx={cardStyle}>
        <CardContent>
          <Typography sx={titleStyle}>{title}</Typography>
          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
            <Box>
              {isLoading ? (
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <CircularProgress size={24} />
                </Box>
              ) : (
                <>
                  <Typography sx={valueStyle}>{value}</Typography>
                  <Box sx={percentageStyle}>
                    <TrendingUpIcon />
                    {trend}
                  </Box>
                </>
              )}
            </Box>
            <Box sx={barChartStyle}>
              {data.map((value, index) => (
                <Box key={index} sx={barStyle(value, index, data)} />
              ))}
            </Box>
          </Box>
        </CardContent>
      </Card>
    );
  };

  return (
    <Grid item xs={12} sm={12} md={12} lg={12} sx={{ background: "transparent" }}>
      <h2 className="text-xl font-bold text-gray-800 pb-4">Analysis</h2>

      <Grid container spacing={2}>
        {/* SUMMARYX */}
        <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2} sx={{ marginTop: -windowHeight / 800 }}>
          {/* <Grid container xs={12} sm={6} md={6} lg={6}> */}

          {/* Deals and documents */}
          <Grid item xs={12} sm={6} md={6} lg={6}>
            {/* deals */}
            <SummaryCard title={"Total Deals"} isLoading={isLoadingDeals} value={totalDeals} trend={"-%"} data={dealsData} />
          </Grid>
          {/* Documents */}
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <SummaryCard title={"Total Documents"} isLoading={isLoadingDocuments} value={totalDocuments} trend={"-%"} data={documentsData} />
          </Grid>
          {/* TotalConv */}
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <SummaryCard title={"Total Conversations"} isLoading={isLoadingConversations} value={totalConversations} trend={"-%"} data={conversationsData} />
          </Grid>
          {/* country */}
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Card sx={{ ...cardStyle }}>
              <div
                style={{
                  height: windowHeight / 6,
                  width: "100%",
                  display: "flex", // Enable flexbox
                  justifyContent: "center", // Center horizontally
                  alignItems: "center", // Center vertically
                }}
              >
                {isLoadingCountryData ? (
                  <CircularProgress size={24} />
                ) : (
                  <ResponsivePie
                    data={countryData && countryData}
                    margin={{ top: 20, bottom: 20, right: 0, left: 0 }}
                    innerRadius={0.5}
                    padAngle={0.7}
                    cornerRadius={3}
                    activeOuterRadiusOffset={8}
                    borderWidth={1}
                    borderColor={{
                      from: "color",
                      modifiers: [["darker", 0.2]],
                    }}
                    arcLinkLabelsSkipAngle={10}
                    arcLinkLabelsTextColor="#333333"
                    arcLinkLabelsThickness={2}
                    arcLinkLabelsColor={{ from: "color" }}
                    arcLabelsSkipAngle={10}
                    arcLabelsTextColor={{
                      from: "color",
                      modifiers: [["darker", 2]],
                    }}
                    defs={[
                      {
                        id: "dots",
                        type: "patternDots",
                        background: "inherit",
                        color: "rgba(255, 255, 255, 0.3)",
                        size: 4,
                        padding: 1,
                        stagger: true,
                      },
                      {
                        id: "lines",
                        type: "patternLines",
                        background: "inherit",
                        color: "rgba(255, 255, 255, 0.3)",
                        rotation: -45,
                        lineWidth: 6,
                        spacing: 10,
                      },
                    ]}
                  />
                )}
              </div>
            </Card>
          </Grid>
        </Grid>

        {/* Cashflow */}
        {/* <Grid item xs={12} sm={6} md={6} lg={6} sx={{ background: "transparent", height: matchesSM ? windowHeight / 2.4 : windowHeight / 2.4 }}>
          <Card sx={{ ...graphStyle, marginTop: windowHeight / 800 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Cashflow forecast
              </Typography>
              <Grid item xs={12} style={{ backgroundColor:'transparent', marginTop: -windowHeight / 23, height: windowHeight / 3.2, width: isMobile() ? windowWidth/0.9 : windowWidth / 2.4 }}>
                <ResponsiveLine
                  data={cashFlowForecastData}
                  margin={{ top: 50, right: isMobile() ? 10 : 110, bottom: 50, left: isMobile() ? windowWidth/10 : 60 }}
                  xScale={{ type: "point" }}
                  yScale={{ type: "linear", min: "auto", max: "auto", stacked: true, reverse: false }}
                  yFormat=" >-.2f"
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    orient: "bottom",
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: "",
                    legendOffset: 36,
                    legendPosition: "middle",
                  }}
                  axisLeft={{
                    orient: "left",
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: "",
                    legendOffset: -40,
                    legendPosition: "middle",
                  }}
                  pointSize={10}
                  pointColor={{ theme: "background" }}
                  pointBorderWidth={2}
                  pointBorderColor={{ from: "serieColor" }}
                  pointLabelYOffset={-12}
                  useMesh={true}
                  theme={{
                    axis: {
                      ticks: {
                        text: {
                          fill: "#6c757d",
                        },
                      },
                      legend: {
                        text: {
                          fill: "#000000",
                        },
                      },
                    },
                    grid: {
                      line: {
                        stroke: "#e0e0e0",
                        strokeWidth: 1,
                      },
                    },
                  }}
                  legends={[
                    {
                      anchor: isMobile() ? "bottom" : "bottom-right",
                      direction: isMobile() ? "row" : "column",
                      justify: false,
                      translateX: isMobile() ? 0 : 100,
                      translateY: isMobile() ? windowHeight/13 : 0,
                      itemsSpacing: 2,
                      itemDirection: "left-to-right",
                      itemWidth: 80,
                      itemHeight: 20,
                      itemOpacity: 0.75,
                      symbolSize: 12,
                      symbolShape: "circle",
                      symbolBorderColor: "rgba(0, 0, 0, .5)",
                      effects: [
                        {
                          on: "hover",
                          style: {
                            itemBackground: "rgba(0, 0, 0, .03)",
                            itemOpacity: 1,
                          },
                        },
                      ],
                    },
                  ]}
                />
              </Grid>
            </CardContent>
          </Card>
        </Grid> */}
      </Grid>
    </Grid>
  );
};

export default Analysis;
