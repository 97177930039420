import React, { useEffect, useState } from "react";
import { Modal, Box, TextField, MenuItem, Button, FormControl, InputLabel, FormHelperText, Select, Checkbox, OutlinedInput, Chip, ListSubheader, Typography, FormControlLabel } from "@mui/material";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";

import { document_types_categories, industries, keyword_types, relationships } from "../../../api/utils/dataModel";
import { useDispatch } from "react-redux";
import { createKeyword } from "../../../redux/actions/keywordAction";
import { formatDate, formatDateWithTimezoneOffset } from "../../../utils/utils";
import { useSnackbar } from "notistack";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import { backendUrl } from "../../../config";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  width: { xs: "90%", sm: "80%", md: "70%", lg: "50%", xl: "50%" }, // Responsive width
  boxShadow: 24,
  p: 4,
};

const AddKeywordPairsFormModal = (props) => {
  const { open, setOpen } = props;
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState({
    name: props.keyword ? props.keyword.name : "",
    industry: props.keyword ? props.keyword.industry : "",
    example: props.keyword ? props.keyword.example : "",
    document_categories: props.keyword ? props.keyword.documents_ids : [],
    is_key: false, // Initialize is_key
    keyValue: "", // Initialize keyValue
    synchronized: false,
  });
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [selectOpen, setSelectOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const { enqueueSnackbar } = useSnackbar();

  const handleCloseSelectDocument = () => {
    setSelectOpen(false);
  };

  const handleClose = () => setOpen(false);

  // const handleChange = (event) => {
  //   setFormValues({ ...formValues, [event.target.name]: event.target.value });
  // };
  const handleChange = (event) => {
    const { name, value, checked, type } = event.target;
    setFormValues({
      ...formValues,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const displaySelectedDocuments = () => {
    const joinedDocuments = formValues.document_categories.join(", ");
    if (joinedDocuments.length > 50) {
      return joinedDocuments.substring(0, 50) + "...";
    }
    return joinedDocuments;
  };
  const displayName = () => {
    if (formValues.name.length > 50) {
      return formValues.name.substring(0, 50) + "...";
    }
    return formValues.name;
  };

  const validateForm = () => {
    let tempErrors = {};
    tempErrors.name = formValues.name.length > 0 ? "" : "Name is required";
    tempErrors.industry = formValues.industry.length > 0 ? "" : "Industry is required";
    tempErrors.example = formValues.example.length > 0 ? "" : "Example is required";
    tempErrors.document_categories = formValues.document_categories.length > 0 ? "" : "Document categories is required";
    tempErrors.keyValue = formValues.is_key && formValues.keyValue.length > 0 ? "" : " is required";
    // Add more validations as needed
    setErrors(tempErrors);

    // Form is valid if there are no errors (all error messages are empty)
    return Object.values(tempErrors).every((x) => x === "");
  };

  const handleOnSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) {
      // If validation fails, prevent form submission
      return;
    }

    setIsSubmitLoading(true);
    const keywordData = {
      name: formValues.name,
      industry: formValues.industry,
      example: formValues.example,
      document_categories: formValues.document_categories, // to setup
      is_key: formValues.is_key,
      key_value: formValues.keyValue,
      synchronized: formValues.synchronized,
    };
    console.log("keywordData", keywordData);
    await dispatch(createKeyword(keywordData));
    props.setSynchronize({ synchronized: false });
    enqueueSnackbar("Created keyword!", { variant: "success" });
    setIsSubmitLoading(false);
    props.setOpen(false);
  };

  const formElementStyle = {
    margin: "5px 0", // Reduced margin
    // padding: "5px 0"
    // height: "px"
  };
  const formInputStyle = {
    height: "50px",
  };

  const inputLabelStyle = {
    top: -4, // Adjust the top position to align the label
  };

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={style}>
        <Typography variant="h6" align="center" gutterBottom>
          Add Keyword Pair
        </Typography>
        <form>
          <TextField
            sx={{
              "& .MuiInputBase-root": formInputStyle,
              "& .MuiInputLabel-root": {
                lineHeight: "17px",
              },
            }}
            label="Name"
            name="name"
            value={formValues.name}
            onChange={handleChange}
            fullWidth
            margin="normal"
            error={Boolean(errors.name)}
            helperText={errors.name}
          />

          <FormControl sx={formElementStyle} fullWidth margin="normal" error={Boolean(errors.industry)}>
            <InputLabel style={inputLabelStyle}>Industry</InputLabel>
            <Select style={formInputStyle} name="industry" value={formValues.industry} onChange={handleChange} label="Industry">
              {industries.map((industry, index) => (
                <MenuItem key={index} value={industry}>
                  {industry}
                </MenuItem>
              ))}
            </Select>
            {errors.industry && <FormHelperText>{errors.industry}</FormHelperText>}
          </FormControl>

          <FormControl sx={formElementStyle} fullWidth margin="normal" error={Boolean(errors.document_categories)}>
            <InputLabel style={inputLabelStyle}>Documents</InputLabel>
            <Select style={formInputStyle} multiple name="document_categories" value={formValues.document_categories} onChange={handleChange} input={<OutlinedInput label="Documents" />} renderValue={(selected) => selected.join(", ")}>
              {Object.entries(document_types_categories).map(([category, docs]) => [
                <ListSubheader key={category}>{category}</ListSubheader>,
                docs.map((doc) => (
                  <MenuItem key={doc.name} value={doc.name}>
                    <Checkbox checked={formValues.document_categories.indexOf(doc.name) > -1} />
                    {doc.name}
                  </MenuItem>
                )),
              ])}
            </Select>
            {errors.document_categories && <FormHelperText>{errors.document_categories}</FormHelperText>}
          </FormControl>

          <div style={{ display: "flex", alignItems: "center", gap: "40px" }}>
            {/* Checkbox for Key Hypothesis */}
            <div style={{ flex: 0.4 }}>
              <FormControlLabel style={formElementStyle} control={<Checkbox checked={formValues.is_key} onChange={handleChange} name="is_key" />} label="Key Hypothesis" />
            </div>

            {/* Conditional TextField for keyValue */}
            {formValues.is_key && (
              <div style={{ flex: 1 }}>
                <TextField
                  sx={{
                    "& .MuiInputBase-root": formInputStyle,
                    "& .MuiInputLabel-root": {
                      lineHeight: "17px",
                    },
                  }}
                  label="Key Hypothesis Value"
                  name="keyValue"
                  value={formValues.keyValue}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  error={Boolean(errors.keyValue)}
                  helperText={errors.keyValue}
                />
              </div>
            )}
          </div>

          <div style={{ marginTop: 0 }}>
            <Typography variant="subtitle1">Example use</Typography>
            <TextareaAutosize
              className={`textarea-autosize w-80 border-gray-300 border p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-800 focus:border-transparent ${errors.example ? "border-red-500" : ""}`}
              maxRows={3}
              placeholder="Example use"
              name="example"
              value={formValues.example}
              onChange={handleChange}
              style={{ width: "100%", marginTop: "8px" }}
            />
            {errors.example && (
              <Typography variant="caption" style={{ color: "red", marginTop: "4px", display: "block" }}>
                {errors.example}
              </Typography>
            )}
          </div>

          <Button disabled={isSubmitLoading} onClick={handleOnSubmit} type="submit" variant="contained" color="primary" style={{ marginTop: "20px", width: "100%" }}>
            {isSubmitLoading ? "Loading..." : "Submit"}
          </Button>
          <Button style={{ marginTop: 10 }} disabled={isSubmitLoading} variant="text" color="primary" onClick={handleClose} fullWidth>
            {"Cancel"}
          </Button>
        </form>
      </Box>
    </Modal>
  );
};

export default AddKeywordPairsFormModal;
