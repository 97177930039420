import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Container, Paper, Typography, TextField, Button, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { windowHeight, windowWidth } from "../../../utils/utils";
import { colors } from "../../../utils/colors";

const RequestFeature = () => {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    featureName: "",
    description: "",
    priority: "",
  });

  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const isValid = () => {
    const errors = {};
    if (!formData.featureName.trim()) errors.featureName = "Feature name is required";
    if (!formData.description.trim()) errors.description = "Description is required";
    if (!formData.priority) errors.priority = "Priority is required";
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isValid()) {
      console.log("Feature Request Submitted:", formData);
      // Dispatch action to submit form data
      // dispatch(submitFeatureRequest(formData));
    }
  };

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: windowHeight / 12 }}>
        <Paper elevation={0} sx={{ paddingLeft: windowWidth / 320, width: "100%", height: "100%", borderBottom: 0.5, borderColor: colors.GRAY_TWO, display: "flex", justifyContent: "start", alignItems: "center" }}>
          <h2 className="text-xl font-bold text-gray-800">Submit Us Your Idea !</h2>
        </Paper>
      </Box>
      <Container sx={{ justifyContent: "center", alignItems: "center", mt: 0 }}>
        <form onSubmit={handleSubmit}>
          <TextField error={!!formErrors.featureName} helperText={formErrors.featureName} fullWidth label="Feature Name" name="featureName" value={formData.featureName} onChange={handleChange} margin="normal" variant="outlined" />
          <TextField error={!!formErrors.description} helperText={formErrors.description} fullWidth label="Description" name="description" value={formData.description} onChange={handleChange} margin="normal" variant="outlined" multiline rows={4} />
          <FormControl fullWidth margin="normal">
            <InputLabel id="priority-label">Priority</InputLabel>
            <Select labelId="priority-label" id="priority" name="priority" value={formData.priority} label="Priority" onChange={handleChange}>
              <MenuItem value={"Low"}>Low</MenuItem>
              <MenuItem value={"Medium"}>Medium</MenuItem>
              <MenuItem value={"High"}>High</MenuItem>
            </Select>
            {!!formErrors.priority && <Typography color="error">{formErrors.priority}</Typography>}
          </FormControl>
          <Button onClick={handleSubmit} type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
            Submit Request
          </Button>
        </form>
      </Container>
    </>
  );
};

export default RequestFeature;
