import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BackButton from "../../../components/BackButton";
import SendIcon from "../../../assets/chat/send.png";
import DisabledSendIcon from "../../../assets/chat/disabledSend.png";
import { List, IconButton, Typography, TextField, Paper, Box, Grid, InputAdornment, Divider, Tooltip, ToggleButtonGroup, ToggleButton, tooltipClasses, FormControlLabel, Switch, styled } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
// import SendIcon from "@mui/icons-material/Send";
import useMessages from "../../../hooks/useMessage";
import { api_subscription_key, backendUrl } from "../../../config";
import { MESSAGE_STATUS } from "../../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { deleteConversations, fetchConversation, setMessages_rd } from "../../../redux/actions/conversationAction";
import { RenderConversations } from "./RenderConversations";
import { store } from "../../../redux/store";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import { set } from "zod";
import { windowHeight, windowWidth } from "../../../utils/utils";
import debounce from "lodash.debounce";

// Custom Styled ToggleButton
const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  flex: 1,
  backgroundColor: "#f0f0f0",
  color: "rgba(0, 0, 0, 0.87)",
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "white",
    backgroundColor: "black",
  },
  padding: "6px 12px",
  "& .MuiToggleButton-label": {
    paddingTop: "6px",
  },
  "&:not(:first-of-type)": {
    borderRadius: theme.shape.borderRadius,
  },
  "&:first-of-type": {
    borderRadius: theme.shape.borderRadius,
  },
  border: "none",
  "&.MuiToggleButtonGroup-grouped:not(:last-of-type)": {
    borderRight: "none",
  },
  "&.MuiToggleButtonGroup-grouped": {
    border: "none",
    "&:not(:first-of-type)": {
      marginLeft: 0,
    },
  },
  "&:hover": {
    backgroundColor: "#d6d6d6", // Slightly darker grey on hover for unselected buttons
  },
}));

const Chat = (props) => {
  const documents = useSelector((state) => state.documents.documents);

  const navigate = useNavigate();
  const { conversationId } = props;

  const dispatch = useDispatch();
  const user_id = useSelector((state) => state.auth.user?.id);

  // const [selectedDocuments, setSelectedDocuments] = useState([]);
  let noting;
  const textFocusRef = useRef();
  const location = useLocation();
  const [userMessage, setUserMessage] = useState("");
  const [chatMode, setChatMode] = useState("deep_answer"); // Initial state
  const [isMessagePending, setIsMessagePending] = useState(false);
  const { userSendMessage, systemSendMessage, messages, setMessages } = useMessages(conversationId || "");
  const [filtredMessages, setFiltredMessages] = useState(null);
  const [searchText, setSearchText] = useState(null);
  const [isMessagesNotEmpty, setisMessagesNotEmpty] = useState(false);

  const handleTextChange = (event) => {
    setUserMessage(event.target.value);
  };

  const handleTextSearchChange = (event) => {
    setSearchText(event.target.value);
    const filtredMessages_ = messages.filter((x) => x.content.includes(event.target.value));
    setFiltredMessages(filtredMessages_);
  };

  const setSuggestedMessage = (text) => {
    setUserMessage(text);
    if (textFocusRef.current) {
      textFocusRef.current.focus();
    }
  };

  const eventSourceRef = useRef(null);
  // const [isSubmitting, setIsSubmitting] = useState(false);
  const isSubmittingRef = useRef(false);
  const submit = () => {
    if (isSubmittingRef.current) {
      console.warn("Submit already in progress.");
      return;
    }
    if (!userMessage || !conversationId) {
      return;
    }

    isSubmittingRef.current = true;
    setIsMessagePending(true);
    userSendMessage(userMessage);
    setUserMessage(""); // Assuming this function is handling local state update to clear the message input

    const messageEndpoint = `${backendUrl}api/conversation/${conversationId}/message`;
    const url = `${messageEndpoint}?user_message=${encodeURIComponent(userMessage)}&user_id=${user_id}&chat_mode=${chatMode}`;

    const events = new EventSource(url, {
      headers: {
        Authorization: `Bearer ${store.getState().auth.accessToken}`, // Adjust accordingly if your store or context provides the token differently
        "Ocp-Apim-Subscription-Key": api_subscription_key, // Include any other necessary headers
      },
    });

    events.onmessage = (event) => {
      const parsedData = JSON.parse(event.data);
      console.log(parsedData);
      systemSendMessage(parsedData); // Dispatch or handle the incoming message data as needed
      if (parsedData) {
        dispatch(setMessages_rd(parsedData)); // Dispatch action to store data in redux
      }
      if (parsedData.status === MESSAGE_STATUS.SUCCESS || parsedData.status === MESSAGE_STATUS.ERROR) {
        events.close();
        setIsMessagePending(false);
        isSubmittingRef.current = false;
      }
    };

    events.onerror = (error) => {
      console.error("Error during SSE connection:", error);
      events.close();
      setIsMessagePending(false);
      isSubmittingRef.current = false;
    };
  };

  // ref to control mount/ unmount
  const isActive = useRef(true);

  const parentScreen = location.state?.parent_screen;

  const isFirstFetch = useRef(true);
  console.log("MATATA", documents);

  useEffect(() => {
    // Define the fetch function
    const fetchFn = async () => {
      // Only perform fetch if it's the first time
      if (isFirstFetch.current && conversationId) {
        isFirstFetch.current = false; // Set to false after the first fetch

        const conversationData = await dispatch(fetchConversation(conversationId, user_id, true));

        if (conversationData?.messages?.length) {
          console.log("CONVERSDSSDSD", conversationData.messages);
          setMessages(conversationData.messages);
          setisMessagesNotEmpty(true);
        }
      }
    };

    // Call the fetch function
    fetchFn();

    // Cleanup function to handle component unmounting
    return () => {
      console.log("User left the chat screen, deleting conversation...", isActive.current);
      if (!isActive.current && conversationId && messages.length === 0 && !isMessagesNotEmpty) {
        console.log("Deleting conversationId:", conversationId);
        // dispatch(deleteConversations([conversationId], user_id));
      }
      isActive.current = false;
    };
  }, [conversationId]);

  useEffect(() => {
    return () => {
      if (eventSourceRef.current && typeof eventSourceRef.current?.close === "function") {
        eventSourceRef.current.close();
        eventSourceRef.current = null;
      }
      isActive.current = false;
    };
  }, []); // Ensure this runs only once upon mounting and unmounting  useEffect(() => {
  useEffect(() => {
    if (textFocusRef.current) {
      textFocusRef.current.focus();
    }
  }, []);
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  // const [isDeepAnswer, setIsDeepAnswer] = useState(false);

  // const handleToggleDeepAnswer = () => {
  //   setIsDeepAnswer(!isDeepAnswer);
  // };
  // const debouncedSubmit = useCallback(debounce(submit, 300), []);

  const handleChatModeChange = (event, newMode) => {
    console.log("newMode", newMode);
    if (newMode !== null) {
      setChatMode(newMode); // Avoid null to prevent no-selection state
    }
  };

  const [textInputRows, setTextInputRows] = useState(1);
  useEffect(() => {
    // count number of lines if userMessage
    if (userMessage.split("\n").length < 4) {
      setTextInputRows(userMessage.split("\n").length);
    }
  }, [userMessage]);
  return (
    <Paper elevation={2} className={`flex flex-grow flex-col w-full"} h-full p-2 mr-2`}>
      <Box display="flex" alignItems="center" marginTop={-windowHeight / 510}>
        <Box style={{ flexGrow: 0, flexShrink: 0 }}>
          <BackButton />
        </Box>

        <Box style={{ display: "flex", width: "100%", alignItems: "center", marginLeft: 10 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={8}>
              <TextField
                fullWidth
                margin="dense"
                variant="outlined"
                placeholder="Search in conversation..."
                onChange={handleTextSearchChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={4}>
              <ToggleButtonGroup
                value={chatMode}
                exclusive
                onChange={handleChatModeChange}
                aria-label="chat mode"
                style={{ width: "100%", height: "40px" }} // Match height with TextField
              >
                <StyledToggleButton style={{ fontSize: 10, marginRight: windowWidth / 220 }} value="automatic" aria-label="automatic">
                  Conversation
                </StyledToggleButton>
                {/* <StyledToggleButton style={{ fontSize: 10 }} value="chat_agent" aria-label="enabled">
                  Chat Agent
                </StyledToggleButton> */}
                <Tooltip title="Diligent Analytical Neural-Network" placement="top">
                  <StyledToggleButton style={{ fontSize: 10 }} value="deep_answer" aria-label="disabled">
                    DAN
                  </StyledToggleButton>
                </Tooltip>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Divider />

      <List sx={{ overflowY: "auto", flexGrow: 1 }}>
        <Typography variant="subtitle1" gutterBottom component="div" sx={{ p: 2 }}>
          Conversation started
        </Typography>

        {filtredMessages?.length > 0 ? (
          <RenderConversations messages={filtredMessages} setUserMessage={setSuggestedMessage} />
        ) : (
          <>
            {searchText?.length > 0 ? (
              <Typography variant="subtitle1" gutterBottom component="div" sx={{ p: 2 }}>
                No message found.
              </Typography>
            ) : (
              <>{messages?.length >= 0 && <RenderConversations messages={messages} setUserMessage={setSuggestedMessage} />}</>
            )}
          </>
        )}
      </List>

      <Divider />
      <Box sx={{ display: "flex", alignItems: "center", padding: 0 }}>
        <TextField
          fullWidth
          margin="dense"
          variant="outlined"
          placeholder="Type your message here..."
          multiline
          rows={textInputRows}
          maxRows={4} // Allows the text field to expand up to 4 lines
          value={userMessage}
          onChange={handleTextChange}
          onKeyDown={async (event) => {
            if (event.key === "Enter" && !event.altKey) {
              // event.preventDefault(); // Prevents the default action of inserting a new line
              if (!isMessagePending && userMessage.trim().length > 0) {
                event.preventDefault();
                submit();
              }
            }
            if (event.altKey && event.key === "Enter") {
              console.log("Alt key pressed");
              setUserMessage(userMessage + "\n");
            }
          }}
          ref={textFocusRef}
        />
        <IconButton onClick={submit} disabled={isMessagePending || userMessage.length === 0} color={"primary"} component="span">
          {isMessagePending || userMessage.length === 0 ? <img src={DisabledSendIcon} alt="SendIcon" className="h-8 w-8" /> : <img src={SendIcon} alt="SendIcon" className="h-8 w-8" />}
        </IconButton>
      </Box>
    </Paper>
  );
};

export default Chat;
