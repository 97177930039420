import React, { useEffect, useState } from "react";
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TableSortLabel, TablePagination, Checkbox, InputAdornment, IconButton, TextField, Grid, Box } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CircularProgress from "@mui/material/CircularProgress";
import NavigationBar from "../../components/NavigationBar";
import { formatDate, formatDateWithTimezoneOffset, getRiskColor, isMobile, windowWidth } from "../../utils/utils";
import { useNavigate } from "react-router-dom";
import { Title } from "@material-ui/icons";
import ChatIcon from "@mui/icons-material/Chat"; // Import at the top of your file

import { sample } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { deleteConversations, fetchConversations } from "../../redux/actions/conversationAction";
import DeleteConfirmModal from "../../components/DeleteConfirmModal";
import { useSnackbar } from "notistack";
import { colors } from "../../utils/colors";
import { backendClient } from "../../api/backend";
import { clearDocuments } from "../../redux/actions/documentsAction";

const ChatHistory = () => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [orderDirection, setOrderDirection] = useState("asc");
  const [valueToOrderBy, setValueToOrderBy] = useState("title");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();
  const open = useSelector((state) => state.navBar.is_open);
  const conversations = useSelector((state) => state.conversation.conversations);
  const user_id = useSelector((state) => state.auth.user?.id);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = conversations?.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex >= 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = async (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase()); // Convert the search query to lowercase for case-insensitive comparison
  };

  const handleOpenConversation = async (chatId) => {
    // navigate("/chat/new");
    await dispatch(clearDocuments());
    navigate(`/chat/${chatId}`);
    enqueueSnackbar("Conversation opened!", { variant: "success" });
  };

  const [isLoadingConversations, setIsLoadingConversations] = useState(false);
  const [totalConversations, setTotalConversations] = useState("");

  const fetch_fn = async (isDelete = false) => {
    setIsLoadingConversations(true);
    await dispatch(fetchConversations(user_id, page + 1, rowsPerPage));
    if (totalConversations === "" || isDelete) {
      // console.log("user_id", user_id);
      const totalConversations_ = await backendClient.countConversations();
      // const totalConversations_ = await backendClient.countConversations();
      console.log("totalConversations_", totalConversations_);
      setTotalConversations(totalConversations_["count"]);
    }
    setIsLoadingConversations(false);
  };

  useEffect(() => {
    fetch_fn(false);
  }, [dispatch, page, rowsPerPage]);

  // Filter conversations based on the search query
  const filteredChats = conversations?.filter((chat) => chat.title?.toLowerCase().includes(searchQuery) || chat.id.toLowerCase().includes(searchQuery));

  const [isDeleteModalVisisble, setIsDeleteModalVisisble] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  const handleDeleteConveration = async () => {
    setIsDeleteModalVisisble(true);
  };

  const handleConfirmDelete = async () => {
    setIsLoadingDelete(true);
    console.log("selected", selected);
    await dispatch(deleteConversations(selected, user_id));
    await fetch_fn(true);
    setSelected([]);
    setIsDeleteModalVisisble(false);
    setIsLoadingDelete(false);
    enqueueSnackbar("Conversation deleted!", { variant: "success" });
  };

  const handleCancelDelete = async () => {
    setIsDeleteModalVisisble(false);
  };

  const documents = useSelector((state) => state.documents.documents);
  const transactions = useSelector((state) => state.transactions.transactions);
  // console.log('PPPPPP-edocuments', documents)
  // console.log('PPPPPP-transactions', transactions)

  return (
    <Grid container sx={{ height: "100vh" }}>
      <Grid item xs={12} md={open ? 9 : 12} lg={open ? 10 : 12} sx={{ transition: "margin 0.5s", marginLeft: open ? "240px" : "75px" }}>
        <Grid sx={{ p: 2, display: "flex", justifyContent: "center", alignItems: "center", bgcolor: "gray.100" }}>
          <Grid sx={{ p: 2, bgcolor: colors.WHITE, boxShadow: 3, borderRadius: 2, width: "100%", height: "100%" }}>
            <Box sx={{ display: "flex", justifyContent: "between", alignItems: "center", mb: 2, pb: 2, borderBottom: 1, borderColor: "divider" }}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <h2 className="text-xl font-bold text-gray-800">Chat History</h2>
              </Box>
            </Box>

            <DeleteConfirmModal isLoadingDelete={isLoadingDelete} type={"Conversations"} open={isDeleteModalVisisble} handleClose={handleCancelDelete} handleConfirm={handleConfirmDelete} />

            {/* Search bar and Explore Transaction button */}
            <div className="flex justify-between items-center mb-4">
              <TextField
                id="search-bar"
                className="flex-grow"
                variant="outlined"
                placeholder="Search by keyword..."
                size="small"
                value={searchQuery}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {!isMobile() ? (
                <>
                  {selected?.length > 0 && (
                    <Button
                      style={{ marginLeft: windowWidth / 80, backgroundColor: selected.length === 0 ? "" : "red", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}
                      variant={selected.length === 0 ? "outlined" : "contained"}
                      disabled={selected.length === 0}
                      onClick={handleDeleteConveration}
                    >
                      {selected.length <= 1 ? "Delete conversation" : "Delete conversations"}
                    </Button>
                  )}
                </>
              ) : (
                <>
                  {selected?.length > 0 && (
                    <Button
                      sx={{
                        marginLeft: windowWidth / 240,
                        flexGrow: 1,
                        whiteSpace: "nowrap",
                        fontSize: "0.75rem",
                        // mr: 1,
                        backgroundColor: selected.length === 0 ? "" : "red",
                      }}
                      variant={selected.length === 0 ? "outlined" : "contained"}
                      disabled={selected.length === 0}
                      onClick={handleDeleteConveration}
                    >
                      {selected.length <= 1 ? "Delete conversation" : "Delete conversations"}
                    </Button>
                  )}
                </>
              )}
            </div>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox indeterminate={selected.length > 0 && selected.length < conversations?.length} checked={conversations?.length > 0 && selected.length === conversations?.length} onChange={handleSelectAllClick} inputProps={{ "aria-label": "select all conversations" }} />
                    </TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell>Creation Date</TableCell>
                    <TableCell>Last Modified</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoadingConversations ? (
                    <TableRow>
                      <TableCell colSpan={5} style={{ textAlign: "center" }}>
                        <CircularProgress size={48} thickness={4} />
                      </TableCell>
                    </TableRow>
                  ) : (
                    <>
                      {filteredChats?.length > 0 ? (
                        filteredChats.map((chat) => {
                          const isItemSelected = selected.indexOf(chat.id) !== -1;
                          const labelId = `enhanced-table-checkbox-${chat.id}`;

                          return (
                            <TableRow hover onClick={(event) => handleClick(event, chat.id)} role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={chat.id} selected={isItemSelected}>
                              <TableCell padding="checkbox">
                                <Checkbox checked={isItemSelected} inputProps={{ "aria-labelledby": labelId }} />
                              </TableCell>
                              <TableCell component="th" scope="row" id={labelId}>
                                <div style={{ fontWeight: "bold" }}>{chat.title}</div>
                                <div>{chat.id}</div>
                              </TableCell>
                              <TableCell>{formatDateWithTimezoneOffset(chat.created_at)}</TableCell>
                              <TableCell>{formatDateWithTimezoneOffset(chat.updated_at)}</TableCell>
                              <TableCell>
                                <Button variant="contained" color="primary" onClick={async () => await handleOpenConversation(chat.id)}>
                                  Open Conversation
                                </Button>
                              </TableCell>
                            </TableRow>
                          );
                        })
                      ) : (
                        <TableRow>
                          <TableCell colSpan={5} style={{ textAlign: "center" }}>
                            <div>
                              <ChatIcon style={{ fontSize: 40, color: "lightgray" }} /> {/* Import ChatIcon from '@mui/icons-material' */}
                              <div>No conversation created yet.</div>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => navigate("/data-import")} // Implement this function
                              >
                                Create a Conversation
                              </Button>
                            </div>
                          </TableCell>
                        </TableRow>
                      )}
                    </>
                  )}
                </TableBody>
              </Table>

              <TablePagination count={totalConversations} rowsPerPageOptions={[5, 10, 25]} component="div" rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} />
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ChatHistory;
