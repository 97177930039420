import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Card, CardContent, Checkbox, CircularProgress, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip, Typography, useTheme } from "@mui/material";
import { getRiskColor, isMobile, windowHeight, windowWidth } from "../../utils/utils";
import { colors } from "../../utils/colors";
import { useEffect, useRef, useState } from "react";
import BackButton from "../../components/BackButton";
import { closeSnackbar, enqueueSnackbar, useSnackbar } from "notistack";
import { clearExploredData } from "../../redux/actions/exploreAction";
import { clearTransactions, fetchTransaction } from "../../redux/actions/transactionAction";
import { clearDocuments, fetchDocuments } from "../../redux/actions/documentsAction";
import { useLocation, useNavigate } from "react-router-dom";
import { createConversation } from "../../redux/actions/conversationAction";
import ChatIcon from "@mui/icons-material/Chat";
import { api_subscription_key, backendUrl } from "../../config";
import { store } from "../../redux/store";
import { addKeyHypothesis, clearHypothesis, deleteHypothesis, extractHypothesis, fetchHypothesis, reExtractOneHypothesis } from "../../redux/actions/hypothesisAction";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import DeleteConfirmModal from "../../components/DeleteConfirmModal";
import DeleteIcon from "@mui/icons-material/Delete";
import SyncIcon from "@mui/icons-material/Sync";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";

export const SummaryCardConv = ({ title, value, handleNavigateToConversation, conversation_id }) => {
  const titleStyle = {
    fontSize: "16px",
    color: colors.GRAY_ONE,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    // fontWeight: 500,
  };

  const valueStyle = {
    fontSize: "28px",
    color: colors.BLACK,
    fontWeight: "semibold",
  };
  return (
    <Card sx={{ borderRadius: 2, boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px" }}>
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography sx={titleStyle} color="text.secondary" gutterBottom>
            {title}
          </Typography>
          <IconButton onClick={() => handleNavigateToConversation(conversation_id)} aria-label="settings">
            <ChatIcon />
          </IconButton>
        </Box>
        <Typography sx={valueStyle} variant="h5" component="div">
          {value}
        </Typography>
      </CardContent>
    </Card>
  );
};

const DealDashboardScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // const transactions = location.state.transactions;
  const open = useSelector((state) => state.navBar.is_open);
  const user_id = useSelector((state) => state.auth.user?.id);
  const key_hypothesis = useSelector((state) => state.hypothesis.hypothesis);
  const transactions = useSelector((state) => state.transactions.transactions);

  const [isStartConversationLoading, setIsStartConversationLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const transactionsIds_documentsIds = location.state.transactionsIds_documentsIds;
  const eventSourceRef = useRef(null);
  const [filtredHypothesis, setFiltredHypothesis] = useState([]);
  const [loadingHypotheses, setLoadingHypotheses] = useState({});
  const [isExtracting, setIsExtracting] = useState(false);
  const [isLoadingExtractHypothesis, setIsLoadingExtractHypothesis] = useState(false);

  const dispatch = useDispatch();
  const [isLoadingExplore, setIsLoadingExplore] = useState(false);
  const [selected, setSelected] = useState([]);
  const [reset, setReset] = useState(false);
  const handleStartConversation = async () => {
    setIsStartConversationLoading(true);
    // get selected transactions
    // clear documents
    await dispatch(clearDocuments());

    // fetch transactions and documents
    for (const transactionId_documentsIds of transactionsIds_documentsIds) {
      await dispatch(fetchDocuments(transactionId_documentsIds["transaction_id"]));
    }

    const conversationId_ = await dispatch(createConversation(transactionsIds_documentsIds, user_id));
    setIsStartConversationLoading(false);
    navigate(`/chat/${conversationId_}`);
    enqueueSnackbar("Conversation started!", { variant: "success" });
  };

  const handleExplore = async () => {
    setIsLoadingExplore(true);
    const snackbar = enqueueSnackbar("Loading transaction...");

    // clear transactions
    await dispatch(clearTransactions());
    // clear explored data
    await dispatch(clearExploredData());
    // clear documents
    await dispatch(clearDocuments());

    // fetch transactions and documents
    for (const transactionId_documentsIds of transactionsIds_documentsIds) {
      // const transaction = await dispatch(fetchTransaction(transactionId_documentsIds["transaction_id"], true));
      await dispatch(fetchDocuments(transactionId_documentsIds["transaction_id"]));
    }

    // navigate to explore page
    navigate(`/deals/hypothesis/explore`);
    setIsLoadingExplore(false);
    closeSnackbar(snackbar);
    enqueueSnackbar("Explore transaction started!", { variant: "success" });
  };

  const [searchQuery, setSearchQuery] = useState("");

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex >= 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  // const filtredHypothesis = key_hypothesis.filter((hypothesis) => {
  //   // Check if the search query is included in any of the hypothesis fields
  //   return (
  //     hypothesis.labels[0].toLowerCase().includes(searchQuery) ||
  //     hypothesis.id.toLowerCase().includes(searchQuery) ||
  //     hypothesis.values[0].toLowerCase().includes(searchQuery) ||
  //     hypothesis.ref_value[0].toLowerCase().includes(searchQuery) ||
  //     hypothesis.risk[0].toLowerCase().includes(searchQuery) ||
  //     hypothesis.conversation_id.toLowerCase().includes(searchQuery)
  //   );
  // });

  const handleReExtractHypothesis = async (event, hypothesis) => {
    event.preventDefault();

    const { id: hypothesisId } = hypothesis;
    setLoadingHypotheses((prevState) => ({ ...prevState, [hypothesisId]: true }));

    try {
      const transaction_id = transactionsIds_documentsIds[0].transaction_id;
      const user_id = store.getState().auth.user.id;
      console.log("Re-extracting hypothesis for", hypothesisId, transaction_id, user_id);

      // Assume dispatching an action to re-extract hypothesis
      await dispatch(reExtractOneHypothesis(transaction_id, user_id, hypothesisId));
      enqueueSnackbar("Hypothesis re-extracted successfully!", { variant: "success" });

      // Show notification or handle success
    } catch (error) {
      console.error("Failed to re-extract hypothesis:", hypothesisId, error);
    } finally {
      setLoadingHypotheses((prevState) => ({ ...prevState, [hypothesisId]: false }));
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = filtredHypothesis.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleNavigateToConversation = (conversation_id) => {
    navigate(`/chat/${conversation_id}`);
    enqueueSnackbar("Conversation opened!", { variant: "success" });
  };

  useEffect(() => {
    let filtredHyothesis_;
    if (searchQuery.length > 0) {
      //  key_hypothesis is list. filter key_hypothesis from each letter in "Extracting Hypothesis"

      // const key_hypothesis_ = key_hypothesis)
      // Create a set of unique characters from the phrase, converted to lowercase
      const phrase = "Extracting Hypothesis";

      const filteredChars = new Set(phrase.toLowerCase());

      // Filter the list, removing elements that contain any character from the phrase
      const filteredList = key_hypothesis.filter((item) => {
        // Ensure that item is a string before checking characters
        if (typeof item === "string") {
          // Convert item to lowercase and check each character
          return !item
            .toLowerCase()
            .split("")
            .some((char) => filteredChars.has(char));
        }
        // If item is not a string, it automatically fails the filter condition
        return false;
      });
      filtredHyothesis_ = filteredList.filter((hypothesis) => {
        return (
          hypothesis.labels[0].toLowerCase().includes(searchQuery) ||
          hypothesis.id.toLowerCase().includes(searchQuery) ||
          hypothesis.values[0].toLowerCase().includes(searchQuery) ||
          hypothesis.ref_value[0].toLowerCase().includes(searchQuery) ||
          hypothesis.risk[0].toLowerCase().includes(searchQuery) ||
          hypothesis.conversation_id.toLowerCase().includes(searchQuery)
        );
      });
    } else {
      const phrase = "Extracting Hypothesis";

      const filteredChars = new Set(phrase.toLowerCase());

      // Filter the list, removing elements that contain any character from the phrase
      const filteredList = key_hypothesis.filter((item) => {
        // Ensure that item is a string before checking characters
        if (typeof item === "string") {
          // Convert item to lowercase and check each character
          return !item
            .toLowerCase()
            .split("")
            .some((char) => filteredChars.has(char));
        }
        // If item is not a string, it automatically fails the filter condition
        return true;
      });
      filtredHyothesis_ = filteredList;
    }
    setFiltredHypothesis(filtredHyothesis_);
  }, [dispatch, key_hypothesis, searchQuery]);

  useEffect(() => {
    console.log(transactions);
    transactions.forEach((transaction) => {
      if ((transaction.is_extracted === "False" || transaction.is_extracted === "Pending") && isExtracting) {
        console.log("Transaction not extracted", transaction);
        const url = `${backendUrl}api/hypothesis/events_extract_hypothesis/?session_id=${transaction.id}`;
        console.log("event source", eventSourceRef.current);
        if (!eventSourceRef.current) {
          setIsExtracting(true);
          console.log("got up to here 1");
          eventSourceRef.current = new EventSource(url, {
            headers: {
              Authorization: `Bearer ${store.getState().auth.accessToken}`,
              "Ocp-Apim-Subscription-Key": api_subscription_key,
            },
          });

          console.log("got up to here 2");
          console.log("got up to here 3");
          console.log("got up to here 4");
          console.log("got up to here 5");
          console.log("got up to here 6");
          console.log("got up to here 7");
          console.log("got up to here 8");
          eventSourceRef.current.onmessage = (event) => {
            console.log("EVENT.DATA", event.data);
            const parsedData = JSON.parse(event.data);
            if ([201, 202].includes(parsedData.status)) {
              dispatch(addKeyHypothesis(parsedData.data));
            } else if ([300].includes(parsedData.status)) {
              eventSourceRef.current.close();
              console.log("All hypothesis has been extracted successfully!");
              setIsExtracting(false);
              enqueueSnackbar("All hypothesis has been extracted successfully!", { variant: "success" });
            } else if ([301].includes(parsedData.status)) {
              dispatch(addKeyHypothesis(parsedData.data));
              eventSourceRef.current.close();
              console.log("All hypothesis has been extracted successfully!");
              enqueueSnackbar("All hypothesis has been extracted successfully!", { variant: "success" });
              setIsExtracting(false);
            }
          };

          eventSourceRef.current.onopen = () => {
            console.log("Connection to server opened.");
          };

          eventSourceRef.current.onerror = (error) => {
            console.error("EventSource failed:", error);
            eventSourceRef.current.close();
            // eventSourceRef.current = null;
          };
        }
      }
    });

    return () => {
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
        // eventSourceRef.current = null;
        console.log("Cleaning up EventSource connection");
      }
      setIsExtracting(false);
    };
  }, [transactions, isLoadingExtractHypothesis, dispatch]);

  useEffect(() => {
    if (isExtracting) {
      console.log("Extraction is already in progress.");
      return;
    }
    let counter = 0;
    for (const transaction of transactions) {
      if (reset) {
        transaction.is_extracted = "False";
      }
      // console.log(isExtracting);
      console.log(transaction);

      if (transaction.is_extracted === "False" || transaction.is_extracted === "Pending" || isLoadingExtractHypothesis || reset) {
        // if (false) {
        // Replace this condition with your actual condition check
        counter += 1;
        setIsExtracting(true);
        console.log("Transaction not extracted", transaction);

        const url = `${backendUrl}api/hypothesis/events_extract_hypothesis/?session_id=${transaction.id}`;
        console.log("Request URL:", url);
        const NewController = new AbortController();
        const { signal } = NewController;
        fetchEventSource(url, {
          method: "GET",
          signal: signal,
          headers: {
            Authorization: `Bearer ${store.getState().auth.accessToken}`,
            "Ocp-Apim-Subscription-Key": api_subscription_key,
          },
          onmessage(event) {
            console.log("EVENT.DATA", event.data);
            console.log("typo", typeof event.data);
            const parsedData = JSON.parse(event.data);
            console.log("paeedDataXX", parsedData);

            if (parsedData.status === 202 || parsedData.status === 201) {
              dispatch(addKeyHypothesis(parsedData.data));
            } else if (parsedData.status === 300) {
              NewController.abort();
              enqueueSnackbar("All hypothesis has been extracted successfully!", { variant: "success" });
              setIsExtracting(false);
            } else if (parsedData.status === 301) {
              dispatch(addKeyHypothesis(parsedData.data));
              NewController.abort();
              enqueueSnackbar("All hypothesis has been extracted successfully!", { variant: "success" });
              setIsExtracting(false);
            }
          },
          onopen() {
            console.log("Connection to server opened.");
          },
          onerror(error) {
            console.error("EventSource failed:", error);
            NewController.abort();
            setIsExtracting(false);
          },
        }).then((controller) => {
          // This will return a controller that can be used to close the connection
          eventSourceRef.current = controller;
        });

        return () => {
          console.log("Cleaning up EventSource connection");
          if (NewController) {
            NewController.abort();
          }
          setIsExtracting(false);
        };
      } else {
        console.log("Transaction already extracted or no need to extract:", transaction);

        // fetch extracted hypothesis
        // dispatch(fetchHypothesis(transaction.id));
      }
    }
    if (counter === transactions.length) {
      setIsExtracting(false);
    }
  }, [transactions, isLoadingExtractHypothesis, dispatch]);

  const handleExtractHypothesis = async () => {
    if (isLoadingExtractHypothesis || isStartConversationLoading || isLoadingExplore) {
      return; // Do nothing if any conditions indicating a loading state or invalid state are true
    }
    setIsLoadingExtractHypothesis(true);
    setIsExtracting(true);
    await dispatch(clearHypothesis());
    const selected_ = transactions.map((x) => x.id);
    console.log("selected", selected_);

    await dispatch(extractHypothesis(selected_, user_id, true));

    setIsLoadingExtractHypothesis(false);
  };

  const [isDeleteModalVisisble, setIsDeleteModalVisisble] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [toDelete, setToDelete] = useState(false);

  const handleDeleteHypothesis = async (event, hypothesis) => {
    if (isLoadingExtractHypothesis || selected.length === 0 || isStartConversationLoading || isLoadingExplore) {
      return; // Do nothing if any conditions indicating a loading state or invalid state are true
    }
    setToDelete(hypothesis);
    setIsDeleteModalVisisble(true);
  };

  const handleConfirmDelete = async () => {
    setIsLoadingDelete(true);
    await dispatch(deleteHypothesis(toDelete.id));
    // await fetch_fn();
    setSelected([]);
    setIsDeleteModalVisisble(false);
    setIsLoadingDelete(false);
    enqueueSnackbar("Hypothesis deleted!", { variant: "success" });
  };

  const handleCancelDelete = async () => {
    setIsDeleteModalVisisble(false);
  };

  return (
    <Grid container sx={{ height: "100vh" }}>
      <Grid item xs={12} md={open ? 9 : 12} lg={open ? 10 : 12} sx={{ transition: "margin 0.5s", marginLeft: open ? "240px" : "75px" }}>
        <Grid sx={{ p: 2, display: "flex", justifyContent: "center", alignItems: "center", bgcolor: "gray.100" }}>
          <Grid sx={{ p: 2, bgcolor: colors.WHITE, boxShadow: 3, borderRadius: 2, width: "100%", height: "100%" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2, pb: 2, borderBottom: 1, borderColor: "divider" }}>
              <Box sx={{ display: "flex", alignItems: "center", gap: isMobile() ? 0 : 2 }}>
                <BackButton />
                <h2 className="text-xl font-bold text-gray-800">Dashboard - Transaction Wind UK</h2>
              </Box>

              {!isMobile() && (
                <Box sx={{}}>
                  <Button isabled={isLoadingExplore} variant={"contained"} color="primary" onClick={handleExplore} style={{ marginRight: windowWidth / 80 }}>
                    Explore
                  </Button>
                  <Button disabled={isStartConversationLoading} variant={"contained"} onClick={handleStartConversation} style={{ marginRight: windowWidth / 80 }}>
                    {isStartConversationLoading ? "Starting conversation..." : "Start Conversation"}
                  </Button>
                  <Button
                    disabled={isLoadingExtractHypothesis}
                    variant={"contained"}
                    onClick={() => {
                      setReset(true);
                      handleExtractHypothesis();
                    }}
                  >
                    {isLoadingExtractHypothesis ? "Resetting..." : "Reset"}
                  </Button>
                </Box>
              )}
            </Box>

            {isMobile() && (
              <Box sx={{ display: "flex" }}>
                <Button
                  sx={{
                    flexGrow: 1,
                    mr: 1, // marginRight: 10px replaced by material ui spacing system
                    whiteSpace: "nowrap",
                    fontSize: "0.75rem",
                  }}
                  variant="contained"
                  color="primary"
                  onClick={handleExplore}
                >
                  Explore
                </Button>
                <Button
                  sx={{
                    flexGrow: 1,
                    whiteSpace: "nowrap",
                    fontSize: "0.75rem",
                  }}
                  disabled={isStartConversationLoading}
                  variant="contained"
                  onClick={handleStartConversation}
                >
                  {isStartConversationLoading ? "Starting conversation..." : "Start Conversation"}
                </Button>
              </Box>
            )}

            <DeleteConfirmModal isLoadingDelete={isLoadingDelete} type={"Deals"} open={isDeleteModalVisisble} handleClose={handleCancelDelete} handleConfirm={handleConfirmDelete} />

            <Grid item xs={12} sm={12} sx={{ background: "transparent", marginBottom: windowHeight / 200 }}>
              {/* <SectorPerformance /> */}
              <Grid item xs={12} sm={12} sx={{ background: "transparent", marginBottom: windowHeight / 200 }}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  {/* Header and potential spacer */}
                  <div>
                    <h2 className="text-xl font-bold text-gray-800">Key Hypothesis</h2>
                  </div>
                </div>
              </Grid>

              {/* <Grid container spacing={2} sx={{ backgroundColor: "", marginTop: -windowHeight / 800 }}>
                {key_hypothesis.map((item, index) => (
                  <Grid item xs={12} sm={3} md={3} lg={3} key={index}>
                    <SummaryCardConv handleNavigateToConversation={handleNavigateToConversation} conversation_id={item.conversation_id} title={item.labels[0]} value={item.values[0]} />
                  </Grid>
                ))}
              </Grid> */}
            </Grid>

            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox indeterminate={selected.length > 0 && selected.length < filtredHypothesis.length} checked={filtredHypothesis.length > 0 && selected.length === filtredHypothesis.length} onChange={handleSelectAllClick} inputProps={{ "aria-label": "select all hypothesis" }} />
                    </TableCell>
                    <TableCell>Id</TableCell>
                    <TableCell>
                      {/* <TableSortLabel active={valueToOrderBy === "risk"} direction={valueToOrderBy === "risk" ? orderDirection : "asc"} onClick={() => handleRequestSort("risk")}> */}
                      Risk
                      {/* </TableSortLabel> */}
                    </TableCell>
                    <TableCell>
                      {/* <TableSortLabel active={valueToOrderBy === "source"} direction={valueToOrderBy === "source" ? orderDirection : "asc"} onClick={() => handleRequestSort("source")}> */}
                      Values
                      {/* </TableSortLabel> */}
                    </TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filtredHypothesis.map((hypothesis) => {
                    const isItemSelected = selected.indexOf(hypothesis.id) !== -1;
                    const labelId = `enhanced-table-checkbox-${hypothesis.id}`;
                    const isHypothesisLoading = loadingHypotheses[hypothesis.id];

                    return (
                      <TableRow hover onClick={(event) => handleClick(event, hypothesis.id)} role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={hypothesis.id} selected={isItemSelected} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={isItemSelected} inputProps={{ "aria-labelledby": labelId }} />
                        </TableCell>
                        <TableCell component="th" scope="row" id={labelId}>
                          <div style={{ fontWeight: "bold" }}>{hypothesis.labels[0]}</div>
                          <div>{hypothesis.id}</div>
                        </TableCell>
                        <TableCell>
                          <span
                            style={{
                              display: "inline-block",
                              padding: "0.5em 1em",
                              borderRadius: "16px",
                              color: "white",
                              backgroundColor: getRiskColor(hypothesis.risk[0]),
                            }}
                          >
                            {hypothesis.risk[0]}
                          </span>
                        </TableCell>
                        <TableCell>
                          <div style={{ fontWeight: "bold" }}>{hypothesis.values[0]}</div>
                          <div>{hypothesis.ref_value[0]}</div>
                        </TableCell>
                        {/* <TableCell>
                          <Button variant="contained" color="primary" onClick={() => handleNavigateToConversation(hypothesis.conversation_id)} style={{ fontSize: "0.75rem" }}>
                            Start Conversation
                          </Button>
                        </TableCell> */}
                        <TableCell>
                          <Tooltip title="Start conversation">
                            <IconButton onClick={(event) => handleNavigateToConversation(hypothesis.conversation_id)}>
                              <QuestionAnswerIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Re-extract">
                            <IconButton onClick={async (event) => await handleReExtractHypothesis(event, hypothesis)}>{isHypothesisLoading ? <CircularProgress size={18} /> : <SyncIcon />}</IconButton>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <IconButton onClick={(event) => handleDeleteHypothesis(event, hypothesis)}>
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              {/* <TablePagination rowsPerPageOptions={[5, 10, 25]} component="div" count={filtredHypothesis ? filtredHypothesis.length : 0} rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} /> */}
              {isExtracting && (
                <div style={{ display: "flex", justifyContent: "center", padding: "10px" }}>
                  <CircularProgress size={38} />
                </div>
              )}
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DealDashboardScreen;
