import { useEffect, useState } from "react";
import { Grid, IconButton, InputBase, Button, Select, MenuItem } from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { ZoomIn, ZoomOut } from "@material-ui/icons";
import { zoomLevels } from "./usePdfViewer";
import { borderColors } from "./pdfColors";
import { windowHeight } from "../../utils/utils";

function PDFOptionsBar({ file, scrolledIndex, numPages, scaleText, nextPage, prevPage, handleZoomIn, handleZoomOut, goToPage, setZoomLevel, zoomInEnabled, zoomOutEnabled }) {
  const [zoomPopoverOpen, setZoomPopoverOpen] = useState(false);
  const [inputValue, setInputValue] = useState(`${scrolledIndex + 1}`);

  useEffect(() => {
    setInputValue(`${scrolledIndex + 1}`);
  }, [scrolledIndex]);

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      const value = parseInt(inputValue, 10);
      if (!isNaN(value) && value > 0) {
        goToPage(value - 1);
      }
    }
  };

  const handleZoomChange = (event) => {
    setZoomLevel(event.target.value);
    setZoomPopoverOpen(false);
  };

  const styles = {
    inputBase: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      maxWidth: "50px",
      minWidth: "20px",
      textAlign: "center",
      border: "1px solid #007BFF",
      borderRadius: 1,
      backgroundColor: "#FFFFFF",
      mr: 1,
      color: "#000000",
      "& input": {
        color: "#000000",
        padding: 0,
        textAlign: "center",
        width: "100%",
      },
    },
    iconButton: {
      color: "#007BFF",
      "&:hover": {
        backgroundColor: "rgba(0, 123, 255, 0.1)",
      },
    },
    button: {
      color: "#007BFF",
      height: windowHeight/21,
      "&:hover": {
        backgroundColor: "rgba(0, 123, 255, 0.2)",
      },
    },
  };

  return (
    <Grid container justifyContent="space-between" alignItems="center" sx={{ borderColor: "divider", paddingBottom: 1 }}>
      <Grid item xs={12} container justifyContent="center" alignItems="center" spacing={2}>
        <Grid item xs={12} md={7} sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
          <IconButton sx={styles.iconButton} onClick={prevPage} disabled={scrolledIndex === 0}>
            <ArrowDropUpIcon />
          </IconButton>
          <InputBase sx={styles.inputBase} value={inputValue} onChange={handleChange} onKeyDown={handleKeyDown} inputProps={{ "aria-label": "current page" }} />
          <span>/ {numPages}</span>
          <IconButton sx={styles.iconButton} onClick={nextPage} disabled={scrolledIndex === numPages - 1}>
            <ArrowDropDownIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12} md={5} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <IconButton sx={styles.iconButton} onClick={handleZoomOut} disabled={!zoomOutEnabled}>
            <ZoomOut />
          </IconButton>
          <Select value={scaleText} onChange={handleZoomChange} displayEmpty inputProps={{ "aria-label": "Zoom level" }} sx={styles.button}>
            {zoomLevels.map((zoom, index) => (
              <MenuItem key={index} value={zoom}>
                {zoom}%
              </MenuItem>
            ))}
          </Select>
          <IconButton sx={styles.iconButton} onClick={handleZoomIn} disabled={!zoomInEnabled}>
            <ZoomIn />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default PDFOptionsBar;
