import { CREATE_CONVERSATION, DELETE_CONVERSATIONS, FETCH_CONVERSATION, FETCH_CONVERSATIONS, SET_MESSAGES, UPDATE_MESSAGE_LIKE } from "../actions/types";

const initialState = {
  conversationId: null,
  conversationsIds: [], // Store the list of transactions here
  conversation: null,
  conversations: null,
};

const conversationReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_CONVERSATION:
      return {
        ...state,
        conversationId: action.payload,
        conversationsIds: [...state.conversationsIds, action.payload],
      };

    case DELETE_CONVERSATIONS:
      return {
        ...state,
        conversationId: state.conversationId && action.payload.includes(state.conversationId) ? null : state.conversationId,
        conversationsIds: state.conversationsIds.filter((t) => !action.payload.includes(t)),
        conversation: state.conversationId && action.payload.includes(state.conversation?.id) ? null : state.converastion,
        conversations: state.conversations.filter((t) => !action.payload.includes(t.id)),
      };

    case SET_MESSAGES:
      return {
        ...state,
        conversation: {
          ...state.conversation,
          messages: [...(state.conversation?.messages || []), action.payload],
        },
      };
    case FETCH_CONVERSATION:
      return {
        ...state,
        conversation: action.payload,
      };

    case FETCH_CONVERSATIONS:
      return {
        ...state,
        conversations: action.payload,
      };

    case UPDATE_MESSAGE_LIKE:
      if (state.conversation && state.conversation.messages) {
        const updatedMessages = state.conversation.messages.map((message) => {
          if (message.id === action.payload.messageId) {
            return { ...message, like: { text: action.payload.text, value: action.payload.value } };
          }
          return message;
        });

        return {
          ...state,
          conversation: {
            ...state.conversation,
            messages: updatedMessages,
          },
        };
      }
      return state;

    default:
      return state;
  }
};

export default conversationReducer;
