import React, { useEffect, useState } from "react";
import Connector from "./components/Connector";
import FilesUpload from "../../components/FilesUpload";
import { useDispatch, useSelector } from "react-redux";
import { connectors } from "../../api/utils/dataModel";
import { Grid, Box } from "@mui/material";
import { colors } from "../../utils/colors";
import { useSnackbar } from "notistack";
import { getUser } from "../../redux/actions/authAction";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import { backendUrl } from "../../config";

const DataScreen = () => {
  const open = useSelector((state) => state.navBar.is_open);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  return (
    <Grid container sx={{ height: "100vh" }}>
      <Grid item xs={12} md={open ? 9 : 12} lg={open ? 10 : 12} sx={{ transition: "margin 0.5s", marginLeft: open ? "240px" : "75px" }}>
        <Grid sx={{ p: 2, display: "flex", justifyContent: "center", alignItems: "center", bgcolor: "gray.100" }}>
          <Grid sx={{ p: 2, bgcolor: colors.WHITE, boxShadow: 3, borderRadius: 2, width: "100%", height: "100%" }}>
            <Box sx={{ display: "flex", justifyContent: "between", alignItems: "center", mb: 2, pb: 2, borderBottom: 1, borderColor: "divider" }}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <h2 className="text-xl font-bold text-gray-800">Data import</h2>
              </Box>
            </Box>

            <Box sx={{ mb: 8 }}>
              <h2 className="text-xl font-bold text-gray-800 pb-4">Import files</h2>
              <FilesUpload uploadedFiles={uploadedFiles} setUploadedFiles={setUploadedFiles} is_upload={true} />
            </Box>

            <Box sx={{ mb: 4 }}>
              <h2 className="text-xl font-bold text-gray-800 pb-4">Connect an existing database</h2>
              <Grid container spacing={2}>
                {connectors.map((connector, index) => (
                  <Grid item xs={12} md={6} lg={4} key={index}>
                    <Connector item={connector} />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DataScreen;
