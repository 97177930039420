import { useSelector } from "react-redux";
import { Box, Button, ButtonGroup, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useSnackbar } from "notistack";
import DealsSummary, { SummaryCard } from "./components/DealsSummary";
import Analysis from "./components/Analysis";
import SectorPerformance from "./components/SectorPerformance";
import { colors } from "../../utils/colors";
import { isMobile, windowHeight, windowWidth } from "../../utils/utils";
import { ResponsiveBar } from "@nivo/bar";

const DashboardScreen = () => {
  const open = useSelector((state) => state.navBar.is_open);
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.up("sm"));

  const sectorPerformanceData = [
    {
      sector: "Oil",
      valuation: 20,
    },
    {
      sector: "Fiber",
      valuation: 20,
    },
    {
      sector: "Wind Offshore",
      valuation: 80,
    },
  ];

  return (
    <Grid container sx={{ height: "100vh" }}>
      <Grid item xs={12} md={open ? 9 : 12} lg={open ? 10 : 12} sx={{ transition: "margin 0.5s", marginLeft: open ? "240px" : "75px" }}>
        <Grid sx={{ p: 2, display: "flex", justifyContent: "center", alignItems: "center", bgcolor: "gray.100" }}>
          <Grid sx={{ p: 2, bgcolor: colors.WHITE, boxShadow: 3, borderRadius: 2, width: "100%", height: "100%" }}>
            <Grid item container spacing={0} sx={{ marginTop: 0, marginBottom: windowHeight / 220 }}>
              {/* DEALS SUMMARY */}
              <Grid item xs={12} sm={6} sx={{ background: "transparent" }}>
                <Analysis />
              </Grid>
              {/* SECTOR PERFORMANCE */}
              <Grid item xs={12} sm={5} sx={{ marginLeft: windowWidth/200, marginTop: isMobile() ? windowHeight / 200 : 0, background: "transparent", height: matchesSM ? windowHeight / 2.4 : windowHeight / 2.4 }}>
                <SectorPerformance />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ marginTop: isMobile() ? windowHeight / 100 : 0 }}>
              {/* ANALYSIS */}
              <DealsSummary />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DashboardScreen;

{
  /* <Grid item xs={12}>
                <Analysis />
              </Grid> */
}
