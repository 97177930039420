// Assuming these are the action types you've defined
import { deepEqual } from "../../utils/utils";
import { ADD_KEY_HYPOTHESIS, CLEAR_HYPOTHESIS, DELETE_HYPOTHESIS, EXTRACT_HYPOTHESIS, EXTRACT_ONE_HYPOTHESIS, FETCH_HYPOTHESIS } from "../actions/types";

const initialState = {
  hypothesis: [],
};

const hypothesisReducer = (state = initialState, action) => {
  switch (action.type) {
    case EXTRACT_HYPOTHESIS:
      return state;

    case EXTRACT_ONE_HYPOTHESIS:
      return {
        ...state,
        hypothesis:
          // find hypothesis with the same id and replace it with the new one
          state.hypothesis.map((hypothesis) => (hypothesis.id === action.payload.id ? action.payload : hypothesis)),
      };

    case DELETE_HYPOTHESIS:
      return {
        ...state,
        hypothesis: state.hypothesis.filter((t) => !action.payload.includes(t.id)),
      };

    case ADD_KEY_HYPOTHESIS:
      // New hypothesis list starting from the current state
      let newHypothesis = [...state.hypothesis];

      // Loop over each item in the action.payload
      action.payload.forEach((payloadItem) => {
        // Check if the item already exists in the hypothesis list
        const isPresent = newHypothesis.some((hyp) => deepEqual(hyp, payloadItem));
        if (!isPresent) {
          // Add to newHypothesis if not already present
          newHypothesis.push(payloadItem);
        }
      });

      return {
        ...state,
        hypothesis: newHypothesis,
      };

    case FETCH_HYPOTHESIS:
      return {
        ...state,
        hypothesis: [...state.hypothesis, ...action.payload],
      };
    case CLEAR_HYPOTHESIS:
      return {
        ...state,
        hypothesis: [],
      };
    default:
      return state;
  }
};

export default hypothesisReducer;
