import React from "react";
import { Modal, Box, Typography, Button } from "@mui/material";

function DeleteConfirmModal({ isLoadingDelete, type, open, handleConfirm, handleClose }) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "90%", sm: "80%", md: "70%", lg: "50%", xl: "50%" }, // Responsive width
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
  };

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {"Confirm deletion of " + type + "?"}
        </Typography>
        <Box
          sx={{
            mt: 2,
            display: "flex",
            justifyContent: "flex-end",
            gap: 2,
          }}
        >
          <Button disabled={isLoadingDelete} variant="outlined" color="primary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            disabled={isLoadingDelete}
            variant="contained"
            color="primary"
            onClick={() => {
              // Handle Confirm action
              handleConfirm();
            }}
          >
            {isLoadingDelete ? "Deleting..." : "Confirm"}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default DeleteConfirmModal;
