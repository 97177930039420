import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { keyframes } from '@emotion/react';

// Define a keyframe animation
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const AnimatedLoadingComponent = ({ message = 'Loading...' }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '90vh', // Adjust this value based on your needs
        gap: 2,
      }}
    >
      <CircularProgress
        size={80}
        sx={{
          animation: `${rotate} 2s linear infinite`,
        }}
      />
      <Typography variant="h6" component="div">
        {message}
      </Typography>
    </Box>
  );
};

export default AnimatedLoadingComponent;
