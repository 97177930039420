import React, { useEffect, useState } from "react";
import { Button, Table, Select, MenuItem, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TableSortLabel, TablePagination, Checkbox, InputAdornment, IconButton, TextField, Tooltip, Grid, Box } from "@mui/material";
// import { Select, MenuItem } from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import CircularProgress from "@mui/material/CircularProgress";
import DownloadIcon from "@mui/icons-material/Download";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz"; // Import at the top of your file
import BackButton from "../../components/BackButton";
import { useDispatch, useSelector } from "react-redux";
import { addDocumentToDocumentsWithTransactions, addDocuments, clearDocuments, deleteDocumentFromTransaction, fetchDocuments, setDocuments, updateDocumentProperty } from "../../redux/actions/documentsAction";
import DeleteConfirmModal from "../../components/DeleteConfirmModal";
import { formatDate, getAllDocumentTypes, handleDownloadDocument, isMobile } from "../../utils/utils";
import AddDocumentModal from "../../components/AddDocumentsModel";
import { fetchTransaction, updateTransactionDocumentUploaded, updateTransactionDocumentsIds, updateTransactionProperty } from "../../redux/actions/transactionAction";
import { document_types_categories } from "../../api/utils/dataModel";
import ModalPdfView from "../../components/ModalPdfView";
import { useSnackbar } from "notistack";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import { api_subscription_key, backendUrl } from "../../config";
import { store } from "../../redux/store";
import { colors } from "../../utils/colors";
import { backendClient } from "../../api/backend";

const DocumentsScreen = () => {
  // STATES
  const navigate = useNavigate();
  const { transactionId } = useParams();
  const location = useLocation();
  const documentIds = location.state?.documentIds;
  const transactionName = location.state?.transactionName;
  const [searchQuery, setSearchQuery] = useState("");
  const [orderDirection, setOrderDirection] = useState("asc");
  const [valueToOrderBy, setValueToOrderBy] = useState("title");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isLoadingDocuments, setIsLoadingDocuments] = useState(false);
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [isDeleteModalVisisble, setIsDeleteModalVisisble] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [isLoadingAddDocument, setIsLoadingAddDocument] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isLoadingChangeCategory, setIsLoadingChangeCategory] = useState(false);
  const [isLoadingChangeType, setIsLoadingChangeType] = useState(false);

  const [document_categories, set_document_categories] = useState(null);
  useEffect(() => {
    set_document_categories(getAllDocumentTypes(document_types_categories));
  }, [document_types_categories]);

  // REDUX
  const dispatch = useDispatch();
  const open = useSelector((state) => state.navBar.is_open);
  const documents = useSelector((state) => state.documents?.documents[0]?.documents);
  const transaction = useSelector((state) => state.transactions.transaction);
  const user = useSelector((state) => state.auth.user);

  console.log("documentXXXs", documents);
  const [totalDocuments, setTotalDocuments] = useState(0);

  const fetch_fn = async () => {
    setIsLoadingDocuments(true);
    await dispatch(clearDocuments());
    await dispatch(fetchDocuments(transactionId, page + 1, rowsPerPage));
    if (totalDocuments === 0) {
      const totalDocuments_ = await backendClient.countDocuments();
      setTotalDocuments(totalDocuments_["count"]);
    }
    await dispatch(fetchTransaction(transactionId));
    setIsLoadingDocuments(false);
  };
  useEffect(() => {
    if (!isLoadingDelete) {
      fetch_fn();
    }
  }, [page, rowsPerPage]);

  useEffect(() => {
    console.log("isLoadingDelete", isLoadingDelete);
    console.log("documentsXX", documents);
    if (documents) {
      setFilteredDocuments(
        documents.filter((document) => {
          return (
            document.name?.toLowerCase().includes(searchQuery) ||
            document.document_category?.toLowerCase().includes(searchQuery) ||
            document.document_type?.toLowerCase().includes(searchQuery) ||
            document.document_format?.toLowerCase().includes(searchQuery) ||
            document.date?.toLowerCase().includes(searchQuery)
          );
        })
      );
    }
  }, [documents, searchQuery]);

  const handleRequestSort = (property) => {
    const isAscending = valueToOrderBy === property && orderDirection === "asc";
    setValueToOrderBy(property);
    setOrderDirection(isAscending ? "desc" : "asc");
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = documents.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex >= 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchQuery(value.toLowerCase()); // Convert the search query to lowercase for case-insensitive comparison
  };

  const handleDeleteDocument = async () => {
    if (isLoadingDelete || isLoadingDocuments || isLoadingAddDocument || isLoadingChangeCategory || isLoadingChangeType) {
      return;
    }
    setIsDeleteModalVisisble(true);
  };

  const handleConfirmDelete = async () => {
    setIsLoadingDelete(true);
    const results = await dispatch(deleteDocumentFromTransaction(transactionId, selected));
    const response = await results.json();
    if (response?.transaction_deleted) {
      navigate("/data-import/transactions");
    }
    // await fetch_fn();
    setSelected([]);
    setIsDeleteModalVisisble(false);
    setIsLoadingDelete(false);
    enqueueSnackbar("Document deleted!", { variant: "success" });
  };

  const handleCancelDelete = async () => {
    setIsDeleteModalVisisble(false);
  };

  const handleDownload = async (event, document) => {
    event.stopPropagation(); // This stops the event from propagating to parent elements
    handleDownloadDocument(document.url, document.name);
  };

  const [currentDocument, setCurrentDocument] = useState({ url: "", name: "" });
  const [isPdfView, setIsPdfView] = useState(false);

  const handleOpenPdfView = async (event, document) => {
    event.stopPropagation();
    setCurrentDocument({ url: document.url, name: document.name });
    setIsPdfView(true);
  };

  const handleOpen = () => {
    // Set the current document here
    setIsPdfView(!isPdfView);
  };

  const handleClose = () => setIsPdfView(false);

  const handleAddDocument = async () => {
    if (isLoadingDelete || isLoadingDocuments || isLoadingAddDocument || isLoadingChangeCategory || isLoadingChangeType) {
      return;
    }
    setIsAddModalVisible(true);
  };

  const handleCancelAddDocument = async () => {
    setIsAddModalVisible(false);
  };

  const { enqueueSnackbar } = useSnackbar();

  const [addDocumentTrigger, setAddDocumentTrigger] = useState(false);

  const handleConfirmAddDocument = async () => {
    if (uploadedFiles.length === 0) {
      enqueueSnackbar("No file selected.", { variant: "error" });
      return;
    }
    setIsLoadingAddDocument(true);
    // upload documents
    const metadata = {};

    // update is uploading document of transction
    await dispatch(updateTransactionDocumentUploaded(transaction, false));

    const uploadedDocumentsStatus = await dispatch(addDocuments(transactionId, uploadedFiles, metadata, true));

    console.log("uploadedDocumentsStatus", uploadedDocumentsStatus);

    // // get documentsIds
    // const documentsIds = uploadedDocuments.map((x) => x.id);
    // // update transaction
    // await dispatch(updateTransactionProperty(transactionId, "documents_ids", documentsIds, true, false));
    // // update documents
    // const updatedDocuments = {
    //   transactionId,
    //   documents: [...documents, ...uploadedDocuments],
    // };
    // await dispatch(setDocuments([updatedDocuments]));

    setIsLoadingAddDocument(false);
    setIsAddModalVisible(false);
    setAddDocumentTrigger(Math.random());
    // enqueueSnackbar("Document added!", { variant: "success" });
    enqueueSnackbar("Uploading documents...");
  };

  useEffect(() => {
    console.log("EFFECTPP", transaction);
    if (!transaction?.document_uploaded || typeof addDocumentTrigger === "number") {
      console.log("INSIDE");
      const NewController = new AbortController();
      const { signal } = NewController;

      const url = `${backendUrl}api/document/events_upload_files/?session_id=${transactionId}`;

      const state = store.getState();
      const token = state.auth.accessToken;
      const headers = {
        Authorization: `Bearer ${token}`,
        "Ocp-Apim-Subscription-Key": api_subscription_key,
      };

      fetchEventSource(url, {
        method: "GET",
        signal: signal,
        headers,
        onmessage: async (event) => {
          console.log("EVENT.DATA", event.data);
          let parsedData;
          try {
            parsedData = JSON.parse(event.data);
          } catch (error) {
            console.error("Error parsing JSON:", error);
            parsedData = null;
          }

          if (parsedData) {
            console.log("parsedData", parsedData);
            if (parsedData.status === "data") {
              console.log("EVENTSOURCE data status...");
              console.log("parsedData second", parsedData);
              await dispatch(updateTransactionDocumentsIds(transactionId, parsedData.data.id));
              await dispatch(addDocumentToDocumentsWithTransactions(parsedData.data, transactionId));
              // setUploadMessage(`Document ${parsedData.data.name} uploaded!`);
              enqueueSnackbar(`Document ${parsedData.data.name} uploaded!`, { variant: "success" });
              // setNumberOfDocumentsUploaded((prev) => prev + 1);
            } else if (parsedData.status === "complete") {
              console.log("EVENTSOURCE closing connection complete...");
              enqueueSnackbar(`All documents has been uploaded!`, { variant: "success" });
              // eventSource.close();
              NewController.abort();
              // setIsUploadingDocuments(false);
            } else if (parsedData.status !== "ping" && parsedData.status !== "data") {
              console.log("EVENTSOURCE setting status...");
              // setUploadMessage(parsedData.status);
            }
          }
        },
        onopen: async () => {
          console.log("Connection to the server opened.");
        },
        onerror: (error) => {
          console.error("EventSource failed:", error);
          NewController.abort();
        },
        onclose: () => {
          console.log("onClose Triggered");
          NewController.abort();
        },
      });

      // Cleanup function to abort the fetchEventSource when the component unmounts
      return () => {
        NewController.abort();
      };
    }
  }, [dispatch, transaction, addDocumentTrigger]);

  const handleTypeChange = async (event, documentId) => {
    event.stopPropagation(); // This stops the event from propagating to parent elements
    setIsLoadingChangeType(true);
    // update document locally
    await dispatch(updateDocumentProperty(documentId, "document_type", [event.target.value], false, false, true));
    enqueueSnackbar("Document type updated!", { variant: "success" });
    setIsLoadingChangeType(false);
  };

  const handleCategoryChange = async (event, documentId) => {
    event.stopPropagation(); // This stops the event from propagating to parent elements
    setIsLoadingChangeCategory(true);
    // update document locally
    await dispatch(updateDocumentProperty(documentId, "document_category", [event.target.value], false, false, true));
    enqueueSnackbar("Document category updated!", { variant: "success" });
    setIsLoadingChangeCategory(false);
  };

  const handleEditClick = (event, documentId) => {
    event.stopPropagation(); // This stops the event from propagating to parent elements
    // Set 'isEditing' to true for the document with the given ID
    // This will switch the cell to the editable mode
  };

  const getTitle = (value) => {
    const max_len = 24;
    if (value.length > max_len) {
      return value.substring(0, 24) + "...";
    } else {
      return value;
    }
  };

  return (
    <Grid container sx={{ height: "100vh" }}>
      <Grid item xs={12} md={open ? 9 : 12} lg={open ? 10 : 12} sx={{ transition: "margin 0.5s", marginLeft: open ? "240px" : "75px" }}>
        <Grid sx={{ p: 2, display: "flex", justifyContent: "center", alignItems: "center", bgcolor: "gray.100" }}>
          <Grid sx={{ p: 2, bgcolor: colors.WHITE, boxShadow: 3, borderRadius: 2, width: "100%", height: "100%" }}>
            <DeleteConfirmModal isLoadingDelete={isLoadingDelete} type={"Documents"} open={isDeleteModalVisisble} handleClose={handleCancelDelete} handleConfirm={handleConfirmDelete} />
            <AddDocumentModal uploadedFiles={uploadedFiles} setUploadedFiles={setUploadedFiles} isLoadingAddDocument={isLoadingAddDocument} open={isAddModalVisible} handleClose={handleCancelAddDocument} handleConfirm={handleConfirmAddDocument} />
            <ModalPdfView open={isPdfView} setOpen={handleOpen} url={currentDocument.url} handleClose={handleClose} />

            {/* HEADER */}
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2, pb: 2, borderBottom: 1, borderColor: "divider" }}>
              <Box sx={{ display: "flex", alignItems: "center", gap: isMobile() ? 0 : 2 }}>
                <BackButton />
                <h2 className="text-xl font-bold text-gray-800">{getTitle(transactionName) + " - Documents"}</h2>
              </Box>

              <Box sx={{}}>
                <Button variant="contained" color="primary" disabled={isLoadingAddDocument} onClick={handleAddDocument}>
                  Add documents
                </Button>
              </Box>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "between", alignItems: "center", mb: 4 }}>
              <TextField
                id="search-bar"
                className="flex-grow"
                variant="outlined"
                placeholder="Search by keyword..."
                size="small"
                value={searchQuery}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              {selected.length > 0 && (
                <Button variant={selected.length === 0 ? "outlined" : "contained"} disabled={isLoadingDelete} onClick={handleDeleteDocument} style={{ marginLeft: 16, backgroundColor: selected.length === 0 ? "" : "red" }}>
                  {selected.length <= 1 ? "Delete document" : "Delete documents"}
                </Button>
              )}
            </Box>

            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox indeterminate={selected.length > 0 && selected.length < documents?.length} checked={documents?.length > 0 && selected.length === documents?.length} onChange={handleSelectAllClick} inputProps={{ "aria-label": "select all documents" }} />
                    </TableCell>
                    <TableCell>Id</TableCell>
                    <TableCell>
                      {/* <TableSortLabel active={valueToOrderBy === "document_category"} direction={valueToOrderBy === "document_category" ? orderDirection : "asc"} onClick={() => handleRequestSort("document_category")}> */}
                      Category
                      {/* </TableSortLabel> */}
                    </TableCell>
                    <TableCell>
                      {/* <TableSortLabel active={valueToOrderBy === "document_type"} direction={valueToOrderBy === "document_type" ? orderDirection : "asc"} onClick={() => handleRequestSort("document_type")}> */}
                      Type
                      {/* </TableSortLabel> */}
                    </TableCell>
                    <TableCell>
                      {/* <TableSortLabel active={valueToOrderBy === "document_format"} direction={valueToOrderBy === "document_format" ? orderDirection : "asc"} onClick={() => handleRequestSort("document_format")}> */}
                      Format
                      {/* </TableSortLabel> */}
                    </TableCell>
                    <TableCell>
                      {/* <TableSortLabel active={valueToOrderBy === "date"} direction={valueToOrderBy === "date" ? orderDirection : "asc"} onClick={() => handleRequestSort("date")}> */}
                      Creation date
                      {/* </TableSortLabel> */}
                    </TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>

                {isLoadingDocuments && transaction?.document_uploaded ? (
                  <TableRow>
                    <TableCell colSpan={7} style={{ textAlign: "center" }}>
                      <CircularProgress size={48} thickness={4} />
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {filteredDocuments?.length > 0 ? (
                      <TableBody>
                        {filteredDocuments.map((document) => {
                          const isItemSelected = selected.indexOf(document.id) !== -1;
                          const labelId = `enhanced-table-checkbox-${document.id}`;

                          return (
                            <TableRow hover onClick={(event) => handleClick(event, document.id)} role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={document.id} selected={isItemSelected} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                              <TableCell padding="checkbox">
                                <Checkbox checked={isItemSelected} inputProps={{ "aria-labelledby": labelId }} />
                              </TableCell>
                              <TableCell component="th" scope="row" id={labelId}>
                                <div style={{ fontWeight: "bold" }}>{document.name}</div>
                                <div>{document.id}</div>
                              </TableCell>
                              {/* <TableCell>
                                <span onClick={() => {
                                  console.log('CLICKED', document_categories)
                                  console.log('CLICKED', document.document_ca)
                                  // document_categories.map((x, index) => {
                                  //   console.log('index', index, x)
                                  // })
                                }}>
                                {document.document_category}</span></TableCell> */}

                              <TableCell>
                                <Select
                                  sx={{
                                    height: "40px", // Adjust the height as needed
                                    ".MuiSelect-select": {
                                      fontSize: "14px", // Adjust the font size as needed
                                    },
                                  }}
                                  value={document.document_category}
                                  onChange={(event) => handleCategoryChange(event, document.id)}
                                  label="Type"
                                  // disabled={user?.email !== process.env.REACT_APP_ADMIN_EMAIL}
                                >
                                  {document_categories.map((x) => (
                                    <MenuItem key={x} value={x}>
                                      {x}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </TableCell>

                              <TableCell>
                                <Select
                                  sx={{
                                    height: "40px", // Adjust the height as needed
                                    ".MuiSelect-select": {
                                      fontSize: "14px", // Adjust the font size as needed
                                    },
                                  }}
                                  value={document.document_type}
                                  onChange={(event) => handleTypeChange(event, document.id)}
                                  label="Type"
                                  // disabled={user?.email !== process.env.REACT_APP_ADMIN_EMAIL}
                                >
                                  {Object.keys(document_types_categories).map((x) => (
                                    <MenuItem key={x} value={x}>
                                      {x}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </TableCell>
                              <TableCell>{document.document_format}</TableCell>
                              <TableCell>{formatDate(document.created_at)}</TableCell>
                              <TableCell>
                                <Tooltip title="View">
                                  <IconButton onClick={(event) => handleOpenPdfView(event, document)}>
                                    <VisibilityIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Download">
                                  <IconButton onClick={(event) => handleDownload(event, document)}>
                                    <DownloadIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    ) : (
                      <TableRow>
                        <TableCell colSpan={7} style={{ textAlign: "center" }}>
                          <div>
                            <SwapHorizIcon style={{ fontSize: 40, color: "lightgray" }} />
                            <div>{searchQuery.length > 0 ? "No document found." : "No document in this transaction."}</div>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={(event) => handleAddDocument(event, document)} // Implement this function
                            >
                              Add Document
                            </Button>
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                )}
              </Table>
              {!transaction?.document_uploaded && (
                <div style={{ display: "flex", justifyContent: "center", padding: "10px" }}>
                  <CircularProgress size={38} />
                </div>
              )}
              <TablePagination rowsPerPageOptions={[5, 10, 25]} component="div" count={documents ? documents.length : 0} rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} />
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DocumentsScreen;
