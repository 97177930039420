import React, { useEffect, useRef, useState } from "react";
// import { MESSAGE_STATUS, ROLE } from "~/types/conversation";
// import { LoadingSpinner } from "~/components/basics/Loading";
import { PiCaretDownBold } from "react-icons/pi";
import { HiOutlineChatAlt2 } from "react-icons/hi";
// import { usePdfFocus } from "~/context/pdf";
import { usePdfFocus } from "../../../components/pdf-viewer/pdfContext";
import { AiFillExclamationCircle, AiOutlineLink } from "react-icons/ai";
// import { borderColors } from "~/utils/colors";
// import { formatDisplayDate } from "~/utils/timezone";
import { borderColors } from "../../../components/pdf-viewer/pdfColors";
import { formatDisplayDate } from "../../../utils/timezone";
import { MESSAGE_STATUS, ROLE } from "../../../utils/constants";
import { fetchDocument, setActivePdf, setExploreCitation, setPdfLoadingTask } from "../../../redux/actions/documentsAction";
import { useDispatch, useSelector } from "react-redux";
import { backendClient } from "../../../api/backend";
import { colors } from "../../../utils/colors";
import { findDocument, getDocumentById, markdownToHtmlWithParagraphs, windowHeight } from "../../../utils/utils";
import { Box, Button, CircularProgress, Grid, Tooltip, Typography } from "@mui/material";
import DanIcon from "../../../assets/chat/dan.png";
import AnimatedSteps from "./AnimatedSteps";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import IconButton from "@mui/material/IconButton";
import * as pdfjsLib from "pdfjs-dist/webpack";

import { Dialog, List, ListItem, ListItemText, Snackbar } from "@mui/material";
import { updateMessageLike } from "../../../redux/actions/conversationAction";
import { set } from "zod";

const CitationDisplay = ({ citation, isSelected, onSelect }) => {
  const { setPdfFocusState } = usePdfFocus();
  const dispatch = useDispatch();
  const documents = useSelector((state) => state.documents.documents);
  const loadingTask = useSelector((state) => state.documents.loadingTask);

  const handleCitationClick = async (documentId, pageNumber) => {
    const foundDoc = findDocument(documents, documentId);
    // console.log('foundXXX', foundDoc)

    if (foundDoc) {
      // console.log("loadingTask", loadingTask.url);
      // console.log("loadingTask", foundDoc.url);

      try {
        // Start loading the new document with proper error handling
        if (loadingTask && loadingTask.destroy) {
          await loadingTask.destroy(); // Ensure to await the destroy call
        }
        console.log("start loading");
        const newLoadingTask = pdfjsLib.getDocument(foundDoc.url);
        newLoadingTask.url = foundDoc.url;
        // setLoadingTask(newLoadingTask);
        await dispatch(setPdfLoadingTask(newLoadingTask));

        const document = getDocumentById(documents, documentId);
        await dispatch(setActivePdf(document));
        // sleep 3 seconds
        await new Promise((resolve) => setTimeout(resolve, 1000));

        // console.log("loadingTask OK");
        const pdfDoc = await newLoadingTask.promise; // Wait for the PDF to be loaded
        console.log("Documentsssss", documentId, pageNumber, citation);
        // console.log("PDF loaded successfully:", pdfDoc);
        // handlePdfFocus(foundDoc.id);
        const citationData = {
          documentId: documentId,
          pageNumber: pageNumber + 1,
          citation: citation.snippet,
          // citation: citation.trim().replace("  ", " "),
        };
        // console.log('citationDataXXX', citationData)
        await dispatch(setExploreCitation(citationData));
        // console.log("Citation set !");
        setPdfFocusState(citationData);
      } catch (error) {
        console.error("Failed to load PDF:", error);
        // Handle PDF loading errors, e.g., show a notification or a fallback UI
      }
    } else {
      console.error("Document not found");
      // Handle case where the document is not found
    }
  };

  const citationClass = isSelected
    ? "border-purple-500" // Class for selected citation
    : colors.GRAY_TWO;

  return (
    <div
      className={`mx-1.5 mb-2 min-h-[25px] min-w-[160px] cursor-pointer rounded border-l-8 bg-gray-00 p-1 hover:bg-gray-15  ${citationClass}`}
      // className={`mx-1.5 mb-2 min-h-[25px] min-w-[160px] cursor-pointer rounded border-l-8 bg-gray-00 p-1 hover:bg-gray-15  ${borderColors[citation.color]}`}
      onClick={() => handleCitationClick(citation.documentId, citation.pageNumber)}
    >
      <div className="flex items-center">
        <div style={{ flex: 0.8 }} className="mr-1 text-xs font-bold text-black overflow-hidden whitespace-nowrap text-overflow-ellipsis">
          {citation.ticker}{" "}
        </div>
        <div style={{ flex: 0.2 }} className="text-[10px]">
          p. {citation.pageNumber + 1}
        </div>
      </div>
      <p className="line-clamp-2 text-[10px] font-light leading-3">{citation.snippet}</p>
    </div>
  );
};

const SubProcessDisplay = ({ subProcesses, like, isOpen, toggleOpen, messageId, showSpinner }) => {
  const subQuestions = [];
  subProcesses?.forEach((subProcess, subProcessIndex) => {
    if (subProcess.metadata_map?.sub_question) {
      subQuestions.push({
        subQuestion: subProcess.metadata_map?.sub_question,
        subProcessIndex,
        subQuestionIndex: subQuestions.length,
      });
    } else if (subProcess.metadata_map?.sub_questions) {
      subProcess.metadata_map?.sub_questions.forEach((subQuestion) => {
        subQuestions.push({
          subQuestion,
          subProcessIndex,
          subQuestionIndex: subQuestions.length,
        });
      });
    }
  });

  const [selected, setSelected] = useState(null);
  const [reviewed, setReviewed] = useState(false);
  const [messageVisible, setMessageVisible] = useState(false);
  const [fadeClass, setFadeClass] = useState("fade-out");
  const [isLikeDisabled, setIsLikeDisabled] = useState(false);

  const dispatch = useDispatch();

  const [selectedCitationText, setSelectedCitationText] = useState(null);
  const handleCitationSelect = (citation) => {
    console.log("handleCitationSelect", citation);
    setSelectedCitationText(citation.text);
    // setPdfFocusState({ documentId, pageNumber: pageNumber, citation: citation});
  };

  const [fetchedDocuments, setFetchedDocuments] = useState([]);

  useEffect(() => {
    if (like?.value === "False") {
      setSelected("down");
      setIsLikeDisabled(true);
    } else if (like?.value === "True") {
      setSelected("up");
      setIsLikeDisabled(true);
    }
  }, []);

  const subQuestionStepsArray = [
    { step: "1", text: "Querying tree" },
    { step: "2", text: "Expanding query" },
    { step: "3", text: "Asking subquestion" },
    { step: "4", text: "Answering subquestion" },
    { step: "5", text: "Summarize analysis" },
    { step: "6", text: "Checking results" },
    //   { step: "7", text: "Sending to user" },
  ];
  const answerStepsArray = [
    { step: "1", text: "Querying tree" },
    { step: "2", text: "Expanding query" },
    // { step: "3", text: "Answering subquestion" },
    // { step: "4", text: "Checking results" },
    //   { step: "7", text: "Sending to user" },
  ];

  const handleSelection = async (choice) => {
    setSelected(selected === choice ? null : choice);
    if (choice === "down") {
      setMessageVisible(false);
    } else {
      setMessageVisible(true);
      await dispatch(updateMessageLike(messageId, true, "N/A"));

      // setReviewed(true);
      setFadeClass("fade-out");

      // Set a timeout to fade out the message after 2 seconds
      setTimeout(() => {
        setFadeClass("hidden");
      }, 500);

      // Set a timeout to hide the message completely after 4 seconds
      setTimeout(() => {
        setMessageVisible(false);
      }, 600);
    }
  };

  // Function to handle button click
  const handleReviewClick = async (option) => {
    await dispatch(updateMessageLike(messageId, false, option));

    // setReviewed(true);
    setFadeClass("fade-out");

    // Set a timeout to fade out the message after 2 seconds
    setTimeout(() => {
      setFadeClass("hidden");
    }, 1000);

    // Set a timeout to hide the message completely after 4 seconds
    setTimeout(() => {
      setMessageVisible(false);
    }, 2000);
  };

  console.log("-----", reviewed, messageVisible);
  console.log("++++", selected, isLikeDisabled);

  const options = ["Shouldn't have used Memory", "Didn't fully follow instructions", "Don't like the style", "Not factually correct", "Refused when it shouldn't have", "More..."];
  return (
    <div key={`${messageId}-sub-process`} className="mt-4 w-full rounded ">
      <div className="text-gray-90 text-sm">Let me check the knowledge base for you. One moment!</div>
      <div className="flex justify-between w-full">
        <div className="flex w-max cursor-pointer items-center rounded p-1 font-nunito text-sm text-gray-90 hover:bg-gray-00" onClick={() => toggleOpen()}>
          View progress
          <div className="px-3 py-2">{isOpen ? <PiCaretDownBold /> : <PiCaretDownBold className="-rotate-90" />}</div>
        </div>
        <div className="flex items-center">
          <IconButton onClick={async () => await handleSelection("up")} aria-label="thumb up" color={selected === "up" ? "primary" : "default"}>
            <ThumbUpIcon style={{ fontSize: 18 }} />
          </IconButton>
          <IconButton onClick={async () => await handleSelection("down")} aria-label="thumb down" color={selected === "down" ? "primary" : "default"}>
            <ThumbDownIcon style={{ fontSize: 18 }} />
          </IconButton>
        </div>
      </div>
      {(!isLikeDisabled || !messageVisible) && selected === "down" && (
        <Box sx={{ background: "#FFF", padding: 1, border: "0.1px solid #FFFFF", borderRadius: 2 }}>
          <h2 className="text-xs font-bold text-gray-600">{"Tell us more:"}</h2>
          <Grid container spacing={2} sx={{ marginTop: -0.5 }}>
            {options.map((option, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <Button
                  onClick={async () => {
                    setIsLikeDisabled(true);
                    setMessageVisible(true);
                    setTimeout(async () => {
                      // Perform your action here
                      console.log("States should be updated now");
                      await handleReviewClick(option);
                    }, 0);
                  }}
                  variant="outlined"
                  fullWidth
                  sx={{ border: "0.1px solid #797E90", color: "grey", textTransform: "none" }}
                >
                  <h2 className="text-xs font-bold text-gray-600">{option}</h2>
                </Button>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
      {messageVisible && isLikeDisabled && <h2 sx={{ padding: 1, border: "0.1px solid #FFFFF", borderRadius: 2, fontSize: "1rem", fontWeight: "bold", color: "gray.600", transition: "opacity 2s ease-out" }}>{"Thank you!"}</h2>}
      {isOpen && (
        <>
          <div className="ml-4 border-l border-l-gray-30 pb-1 pl-4 font-nunito text-[11px] font-light text-gray-60">
            <div>Question Received</div>
            {subQuestions.length > 0 && (
              <div key={`${messageId}-sub-process`} className="text-gray-60">
                <div>
                  {subQuestions.length === 0 && <AnimatedSteps stepsArray={subQuestionStepsArray} />}
                  {subQuestions.map(({ subQuestion, subQuestionIndex, subProcessIndex }) => {
                    const hasCitations = !!subQuestion.citations;
                    return (
                      <div key={`${messageId}-${subProcessIndex}-${subQuestionIndex}`}>
                        Generated Sub Query #{subQuestionIndex + 1}{" "}
                        <div className="flex w-11/12 flex-col rounded border">
                          <div className="rounded-t border-b bg-gray-00 p-2 font-bold text-gray-90">{subQuestion.question}</div>
                          {subQuestion.answer ? (
                            <div className="overflow-scroll p-2 text-[11px] font-light">{subQuestion.answer}</div>
                          ) : (
                            <>
                              {!hasCitations && (
                                <div className="flex justify-center">
                                  <AnimatedSteps stepsArray={answerStepsArray} />
                                </div>
                              )}
                            </>
                          )}

                          {hasCitations && (
                            <div className=" mr-2 flex w-full overflow-scroll pl-2 ">
                              {subQuestion.citations?.map((citation, citationIndex) => {
                                const yearDisplay = "";
                                const isSelected = citation.text === selectedCitationText;
                                return (
                                  <CitationDisplay
                                    key={`${messageId}-${subProcessIndex}-${subQuestionIndex}-${citationIndex}`}
                                    isSelected={isSelected}
                                    onSelect={() => handleCitationSelect(citation)}
                                    citation={{
                                      documentId: citation.document_id,
                                      snippet: citation.text,
                                      pageNumber: citation.page_number,
                                      // ticker: citationDocument?.ticker,
                                      citation: citation,
                                      ticker: citation.document_name,
                                      displayDate: yearDisplay,
                                      color: citation.color,
                                    }}
                                  />
                                );
                              })}
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
          {showSpinner && (
            <div className="ml-2.5 mt-1 ">
              {/* <div className="loader h-3 w-3 rounded-full border-2 border-gray-200 ease-linear"></div> */}
              <CircularProgress size={windowHeight / 42} />
            </div>
          )}
          <div className="pb-2"></div>
        </>
      )}
    </div>
  );
};

const UserDisplay = ({ message, showLoading }) => {
  return (
    <>
      <div className="flex border-r bg-gray-00 pb-4">
        <div className="mt-4 w-1/5 flex-grow text-right font-nunito text-gray-60">
          <div className="flex items-center justify-center">{formatDisplayDate(message?.created_at)}</div>
        </div>
        <div className="mt-4 w-4/5 pr-3 font-nunito font-bold text-gray-90">{message?.content}</div>
      </div>

      {showLoading && (
        <div className="flex border-b-2 pb-4">
          <div className="w-1/5"></div>
          <div className="w-4/5">
            <SubProcessDisplay key={`${message?.id}-loading-sub-process`} messageId={message?.id} subProcesses={[]} isOpen={true} toggleOpen={() => {}} showSpinner={showLoading} />
          </div>
        </div>
      )}
    </>
  );
};

const ErrorMessageDisplay = () => {
  return (
    <div className="mt-2 flex w-[80%] items-center rounded border border-red-500 bg-red-100 bg-opacity-20 p-1">
      <div className="ml-2">
        <AiFillExclamationCircle className="fill-red-500" size={20} />
      </div>
      <div className="ml-4 text-red-400">Error: please try again later or reach out to support.</div>
    </div>
  );
};

const AssistantDisplay = ({ message, documentsIds, showLoading }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const isMessageSuccessful = message?.status === MESSAGE_STATUS.SUCCESS;
  const isMessageError = message?.status === MESSAGE_STATUS.ERROR;

  useEffect(() => {
    if (isMessageSuccessful) {
      setIsExpanded(false);
    }
  }, [isMessageSuccessful]);

  const styleSheet = `
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in-up {
  animation: fadeInUp 1s ease-out forwards;
}
`;
  const getChatMode = (chat_mode) => {
    switch (chat_mode?.toLowerCase()) {
      case "dan":
        return "DAN";
      case "oneshot":
        return "OA";
      default:
        return "OA";
    }
  };
  const tooltipTitle = getChatMode(message.chat_mode) === "DAN" ? "Diligent Analytical Neural-Network" : "Conversation Agent";

  return (
    <div className="border-b pb-4">
      <div className="flex ">
        <>
          <style>{styleSheet}</style>
          <div className="w-1/5 flex flex-col justify-center items-center text-4xl fade-in-up" style={{ width: "20%" }}>
            {/* <div style={{ fontSize: "2.5rem" }}>🧒</div> */}
            <img src={DanIcon} alt="DanIcon" className="h-16" />
            <Tooltip title={tooltipTitle} placement="bottom">
              <span style={{ marginTop: windowHeight / 180, background: "#000", textAlign: "center", justifyContent: "center", alignItems: "center", alignContent: "center", color: "#fff", height: windowHeight / 45, padding: "0.5px 10px", borderRadius: "2px", fontSize: "8px", display: "flex" }}>
                {getChatMode(message.chat_mode)}
              </span>
            </Tooltip>
          </div>
        </>

        <div className="w-4/5">
          {!isMessageError && (
            <div className="flex flex-col">
              <SubProcessDisplay key={`${message?.id}-sub-process`} like={message?.like} subProcesses={message?.sub_processes || []} isOpen={isExpanded} toggleOpen={() => setIsExpanded((prev) => !prev)} showSpinner={!isMessageSuccessful} messageId={message?.id} documentsIds={documentsIds} />
            </div>
          )}
          {isMessageError && <ErrorMessageDisplay />}
        </div>
      </div>

      {!isMessageError && (
        <>
          <div className="flex items-center justify-center">
            <div className="my-3 w-11/12 border-[.5px]"></div>
          </div>
          <div className="flex ">
            <div className="w-1/5"></div>
            <div className="w-4/5">
              {/* <p className="relative mb-2 mt-2 whitespace-pre-wrap pr-3 font-nunito font-bold text-gray-90">{markdownToHtmlWithParagraphs(message?.content)}</p> */}
              <div className="relative mb-2 mt-2 whitespace-pre-wrap pr-3 font-nunito font-bold text-gray-90" dangerouslySetInnerHTML={{ __html: markdownToHtmlWithParagraphs(message?.content) }} />
              {/* <div dangerouslySetInnerHTML={{ __html: htmlContent }} /> */}
              {message.chat_mode === "oneshot" && <p className="flex items-center justify-start p-1 text-xs text-gray-60">The conversation agent offers quick, partial responses; for full answers, use the DAN.</p>}
              <p className="flex items-center justify-start p-1 text-xs text-gray-60">We encourage you to verify every source before using the answer in your work.</p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export const RenderConversations = ({ messages, setUserMessage }) => {
  const lastElementRef = useRef(null);

  useEffect(() => {
    if (lastElementRef.current) {
      lastElementRef.current.scrollIntoView();
    }
  }, [messages]);

  const showLoading = messages[messages.length - 1]?.role === ROLE.USER;
  return (
    <div className="box-border flex h-full flex-col justify-start font-nunito text-sm text-[#2B3175]">
      {messages.map((message, index) => {
        let display;
        if (message?.role === ROLE.ASSISTANT) {
          display = <AssistantDisplay message={message} key={`${message?.id}-answer-${index}`} showLoading={index === messages.length - 1 ? showLoading : false} />;
        } else if (message?.role === ROLE.USER) {
          display = <UserDisplay message={message} key={`${message?.id}-question-${index}-user`} showLoading={index === messages.length - 1 ? showLoading : false} />;
        } else {
          display = (
            <div
              onClick={() => {
                console.log("messages", JSON.parse(message));
              }}
            >
              Sorry, there is a problem.
            </div>
          );
        }
        if (index === messages.length - 1) {
          return (
            <div className="mb-4 flex flex-col" key={`message-${message?.id}`}>
              {display}
            </div>
          );
        } else {
          return (
            <div className="flex flex-col" key={`${message?.id}-${index}`}>
              {display}
            </div>
          );
        }
      })}
      {messages.length === 0 && (
        <div className="flex h-full items-center justify-center ">
          <div className="flex w-full flex-col items-center justify-center">
            <div>
              <HiOutlineChatAlt2 size={40} />
            </div>
            <div className="mb-2 w-3/4 text-center text-lg font-bold">Ask DueDil.ai questions about the documents you&apos;ve selected, such as:</div>
            <div className="m-auto flex w-full flex-wrap justify-center">
              <button onClick={() => setUserMessage("What is the PPA Price?")} className="m-1 flex-shrink rounded-full border border-gray-60 px-3 py-1 hover:bg-gray-15">
                What is the PPA Price?
              </button>
              <button onClick={() => setUserMessage("What is the margin on the debt?")} className="m-1 flex-shrink rounded-full border border-gray-60 px-3 py-1 hover:bg-gray-15">
                What is the margin on the debt?
              </button>
              <button onClick={() => setUserMessage("What is the production of the transaction?")} className="m-1 flex-shrink rounded-full border border-gray-60 px-3 py-1 hover:bg-gray-15">
                What is the production of the transaction
              </button>
            </div>
          </div>
        </div>
      )}
      <div ref={lastElementRef}></div>
    </div>
  );
};
