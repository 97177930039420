// import { MAX_NUMBER_OF_SELECTED_DOCUMENTS } from "~/hooks/useDocumentSelector";
// import { documentColors } from "../../utils/colors";

import { documentColors } from "../../components/pdf-viewer/pdfColors";

const createMetadataFromBackendDoc = (backendDoc) => {
  if (backendDoc.metadata_map?.SecDocument) {
    const doc = backendDoc.metadata_map?.SecDocument;
    const frontendDocType = doc.doc_type === "TenK" ? "TenK" : "TenQ";

    return {
      metadataType: "SecDocument",
      ticker: doc.company_ticker,
      fullName: doc.company_name,
      companyName: doc.company_name,
      docType: frontendDocType,
      year: doc.year,
      quarter: doc.quarter || undefined,
    };
  }

  if (backendDoc.metadata_map?.PublicBond) {
    const doc = backendDoc.metadata_map?.PublicBond;
    return {
      metadataType: "PublicBond",
      ticker: doc.company_ticker,
      fullName: doc.company_name,
      country: doc.country,
      issuer: doc.issuer,
      maturityDate: new Date(doc.maturity_date),
    };
  }

  if (backendDoc.metadata_map?.PublicEquity) {
    const doc = backendDoc.metadata_map?.PublicEquity;
    return {
      metadataType: "PublicEquity",
      ticker: doc.company_ticker,
      fullName: doc.company_name,
      country: doc.country,
      companyName: doc.company_name,
      exchange: doc.exchange,
    };
  }

  if (backendDoc.metadata_map?.PrivateInvestorReport) {
    const doc = backendDoc.metadata_map?.PrivateInvestorReport;
    return {
      metadataType: "PrivateInvestorReport",
      ticker: doc.company_ticker,
      fullName: doc.company_name,
      companyName: doc.company_name,
      reportDate: new Date(doc.report_date),
    };
  }

  if (backendDoc.metadata_map?.TransactionData) {
    const doc = backendDoc.metadata_map?.TransactionData;
    return {
      metadataType: "TransactionData",
      ticker: doc.company_ticker,
      fullName: doc.company_name,
      docType: doc.doc_type,
      year: doc.year,
      quarter: doc.quarter || undefined,
      acquirer: doc.acquirer,
      target: doc.target,
      transactionDate: doc.transaction_date ? new Date(doc.transaction_date) : undefined,
    };
  }

  if (backendDoc.metadata_map?.SchoolTopic) {
    const doc = backendDoc.metadata_map?.SchoolTopic;
    return {
      metadataType: "SchoolTopic",
      ticker: doc.company_ticker,
      fullName: doc.company_name,
      grade: doc.grade,
      subject: doc.subject,
      documentType: doc.document_type,
    };
  }

  // throw new Error(`Unsupported backend document type for ID: ${backendDoc.id}`);
};

export const fromBackendDocumentToFrontend = (backendDocuments) => {
  return backendDocuments.map((backendDoc, index) => {
    const colorIndex = index < 10 ? index : 0; // we have 10 colors for 10 documents
    return {
      id: backendDoc.id,
      url: backendDoc.url,
      name: backendDoc.name,
      document_type: backendDoc.document_type,
      document_format: backendDoc.document_format,
      document_category: backendDoc.document_category,
      transactions_ids: backendDoc.transactions_ids,
      metadata: createMetadataFromBackendDoc(backendDoc),
      // color: documentColors[colorIndex],
      created_at: backendDoc.created_at,
      updated_at: backendDoc.updated_at,
      organization_id: backendDoc.organization_id,
    };
  });
};
