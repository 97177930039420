import React, { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DataIcon from "@mui/icons-material/InsertDriveFile";
import ConversationIcon from "@mui/icons-material/Chat";
import HypothesisIcon from "@mui/icons-material/Assessment";
import SettingsIcon from "@mui/icons-material/Settings";
import Logo from "../assets/logo/logo.png";
import MenuIcon from "@mui/icons-material/Menu";
import { useDispatch, useSelector } from "react-redux";
import { setOpen } from "../redux/actions/navBarActions";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { Tooltip } from "@mui/material";

const NavigationBar = () => {
  const navigate = useNavigate();
  const open = useSelector((state) => state.navBar.is_open);
  const dispatch = useDispatch();

  const location = useLocation();
  // const [open, setOpen] = useState(true);
  const theme = useTheme();
  const isSelected = (path) => location.pathname === path || location.pathname.split("/")[1] === path.split("/")[1];

  const pages = [
    { text: "Dashboard", icon: <SpaceDashboardIcon />, path: "/dashboard" },
    { text: "Data import", icon: <DataIcon />, path: "/data-import" },
    { text: "Deals", icon: <AccountBalanceWalletIcon />, path: "/deals" },
    { text: "Keywords", icon: <HypothesisIcon />, path: "/keyword-pairs" },
    { text: "Conversation", icon: <ConversationIcon />, path: "/chat" },
  ];

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

  const handleDrawerClose = () => {
    dispatch(setOpen(false));
  };
  const handleDrawerOpen = () => {
    dispatch(setOpen(true));
  };

  //
  return (
    // <Drawer open={open} variant="permanent" anchor="left" sx={{ width: open ? 240 : 75, flexShrink: 0, "& .MuiDrawer-paper": { width: open ? 240 : 75, boxSizing: "border-box" } }}>
    <Drawer
      open={open}
      variant="permanent"
      anchor="left"
      sx={{
        // height: "100%",
        width: open ? 240 : 75,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: open ? 240 : 75,
          boxSizing: "border-box",
          overflowX: "hidden",
        },
        "&::-webkit-scrollbar": { display: "none" },
      }}
    >
      <DrawerHeader className="bg-blue-800">
        {open ? (
          <IconButton onClick={handleDrawerClose}>{theme.direction === "rtl" ? <ChevronRightIcon className="text-slate-50" /> : <ChevronLeftIcon className="text-slate-50" />}</IconButton>
        ) : (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", marginRight: "9px" }}>
            <IconButton className="justify-center items-center content-center bg-slate-50" onClick={handleDrawerOpen}>
              <MenuIcon className="text-slate-50" />
            </IconButton>
          </div>
        )}
      </DrawerHeader>
      {/* <Divider /> */}
      <div className="flex flex-col items-center justify-between h-full bg-blue-800 text-white p-4">
        <div className="flex flex-col items-center">
          <div onClick={() => navigate("/")}>
            <img src={Logo} alt="Logo" className="h-12 w-12 mb-4" />
          </div>
          <List>
            {pages.map(({ text, icon, path }) =>
              !open ? (
                <Tooltip title={text} key={text} placement="right">
                  <ListItem
                    button
                    component={Link}
                    to={path}
                    key={text}
                    sx={{
                      width: open ? 180 : 60,
                      backgroundColor: isSelected(path) ? "rgba(255, 255, 255, 0.2)" : "transparent",
                      color: isSelected(path) ? "white" : "inherit",
                      borderRadius: isSelected(path) ? "4px" : "0", // Adjust the radius as needed
                      "&:hover": {
                        backgroundColor: "rgba(255, 255, 255, 0.1)",
                        borderRadius: "4px", // Adjust the radius as needed
                      },
                      mr: open ? 3 : "auto",
                      justifyContent: open ? "initial" : "center",
                      alignItems: "center",
                      alignContent: "center",
                      alignSelf: "cener",
                      height: "50px",
                    }}
                  >
                    <ListItemIcon className="justify-center" sx={{ color: isSelected(path) ? "white" : "inherit" }}>
                      {icon}
                    </ListItemIcon>
                    {open && <ListItemText primary={text} />}
                  </ListItem>
                </Tooltip>
              ) : (
                <ListItem
                  button
                  component={Link}
                  to={path}
                  key={text}
                  sx={{
                    width: open ? 180 : 60,
                    backgroundColor: isSelected(path) ? "rgba(255, 255, 255, 0.2)" : "transparent",
                    color: isSelected(path) ? "white" : "inherit",
                    borderRadius: isSelected(path) ? "4px" : "0", // Adjust the radius as needed
                    "&:hover": {
                      backgroundColor: "rgba(255, 255, 255, 0.1)",
                      borderRadius: "4px", // Adjust the radius as needed
                    },
                    mr: open ? 3 : "auto",
                    justifyContent: open ? "initial" : "center",
                    alignItems: "center",
                    alignContent: "center",
                    alignSelf: "cener",
                    height: "50px",
                  }}
                >
                  <ListItemIcon className="justify-center" sx={{ color: isSelected(path) ? "white" : "inherit" }}>
                    {icon}
                  </ListItemIcon>
                  {open && <ListItemText primary={text} />}
                </ListItem>
              )
            )}
          </List>
        </div>
        <List>
          <ListItem
            button
            component={Link}
            to="/settings"
            key="Settings"
            sx={{
              width: open ? 180 : 60,
              backgroundColor: isSelected("/settings") ? "rgba(255, 255, 255, 0.2)" : "transparent",
              color: isSelected("/settings") ? "white" : "inherit",
              "&:hover": {
                backgroundColor: "rgba(255, 255, 255, 0.1)",
                borderRadius: "4px", // Adjust the radius as needed
              },
              height: "50px",
            }}
          >
            <ListItemIcon sx={{ color: isSelected("/settings") ? "white" : "inherit" }}>
              <SettingsIcon />
            </ListItemIcon>
            {open && <ListItemText primary={"Settings"} />}
          </ListItem>
        </List>
      </div>
    </Drawer>
  );
};

export default NavigationBar;
