import React, { useEffect, useState } from "react";
// import { List, ListItem, ListItemIcon, ListItemText, Checkbox, TextField, InputAdornment, IconButton, Divider } from "@mui/material";
import { List, ListItem, Checkbox, TextField, InputAdornment, IconButton, Divider, Select, MenuItem, FormControl, InputLabel, Button, TextareaAutosize, Tooltip, tooltipClasses, FormControlLabel, Switch } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { useDispatch, useSelector } from "react-redux";
import { exploreTransactions, setFiltredExploredData } from "../../../redux/actions/exploreAction";
import CircularProgress from "@mui/material/CircularProgress";
import { usePdfFocus } from "../../../components/pdf-viewer/pdfContext";
import styled from "@emotion/styled";
import { findDocument, isMobile, parseSingleQuoteJSON, windowWidth } from "../../../utils/utils";
import { useMultiplePdfs } from "../../../components/pdf-viewer/useMultiplePdfs";
import { closeSnackbar, useSnackbar } from "notistack";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import * as pdfjsLib from "pdfjs-dist/webpack";
import { setActivePdf, setExploreCitation, setPdfLoadingTask } from "../../../redux/actions/documentsAction";
import { multiHighlight } from "../../../components/pdf-viewer/multi-line-highlight";

const DocumentsList = (props) => {
  const [searchQuery, setSearchQuery] = useState("");
  const exploredData = useSelector((state) => state.explore.exploredData);

  const { enqueueSnackbar } = useSnackbar();

  // Filter documents based on search query
  const filteredExploredData = useSelector((state) => state.explore.filtredExploredData);
  // const [filteredExploredData, setFiltredExploredData] = useState(exploredData);

  const dispatch = useDispatch();
  const transactions = useSelector((state) => state.transactions.transactions);
  const transactionsIds = transactions.map((x) => x.id);
  const documents = useSelector((state) => state.documents.documents);

  const docs_ = documents && documents.flatMap((item) => item.documents);

  const { setPdfFocusState } = usePdfFocus();
  // const { handlePdfFocus } = useMultiplePdfs(Array.from(new Map(docs_.map((pdf) => [pdf?.url, pdf])).values()));

  // const [loadingTask, setLoadingTask] = useState(null);
  const loadingTask = useSelector((state) => state.documents.loadingTask);

  // useEffect(() => {
  //   return () => {
  //     // Cleanup function to ensure the worker is destroyed when the component unmounts
  //     if (loadingTask) {
  //       try {
  //         loadingTask.destroy(); // This destroys the worker and cleans up
  //       } catch (error) {
  //         console.error("DOCUMENTLIST - Failed to destroy PDF worker:", error);
  //       }
  //     }
  //   };
  // }, [loadingTask]);

  const [isLoadingCitationClick, setIsLoadingCitationClick] = useState(false);

  const handleCitationClick = async (documentId, pageNumber, citation) => {
    if (isLoadingCitationClick) {
      return;
    }
    setIsLoadingCitationClick(true);
    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    for (let i = 0; i < 1; i++) {
      if (i > 0) await sleep(1000);
      // Loop to repeat the function body twice
      const foundDoc = findDocument(documents, documentId);

      if (foundDoc) {
        try {
          // Start loading the new document with proper error handling
          const newLoadingTask = pdfjsLib.getDocument(foundDoc.url);
          newLoadingTask.url = foundDoc.url;
          await dispatch(setPdfLoadingTask(newLoadingTask));
          const pdfDoc = await newLoadingTask.promise; // Wait for the PDF to be loaded
          console.log("OK Citation", citation, pageNumber);
          const citationData = {
            documentId,
            pageNumber: pageNumber,
            citation: citation,
          };
          await dispatch(setExploreCitation(citationData));
          setPdfFocusState(citationData);
        } catch (error) {
          console.error("Failed to load PDF:", error);
        }
      } else {
        console.error("Document not found");
      }
    }
    setIsLoadingCitationClick(false);
  };

  // const handleCitationClick = async (documentId, pageNumber, citation) => {
  //   const foundDoc = findDocument(documents, documentId);
  //   console.log("foundDocXX", foundDoc);
  //   if (foundDoc) {
  //     try {
  //       let loadingTask = pdfjsLib.getDocument(foundDoc?.url);
  //       console.log("found task", loadingTask);
  //       if (loadingTask._worker && !loadingTask._worker.destroyed) {
  //         await loadingTask._worker.destroy();
  //       }
  //     } catch {
  //       console.log("error");
  //     }
  //     handlePdfFocus(foundDoc.id);
  //     // let loadingTask = pdfjsLib.getDocument(foundDoc.url);
  //     // if (loadingTask._worker && !loadingTask._worker.destroyed) {
  //     //   loadingTask._worker.destroy();
  //     // }
  //   }
  //   //
  //   // setPdfFocusState({ documentId, pageNumber, citation: citation.trim().replace('  ', ' ')});
  //   console.log("seto", { documentId, pageNumber: pageNumber, citation: citation });
  //   setPdfFocusState({ documentId, pageNumber: pageNumber + 1, citation: citation });
  //   // multiHighlight(pdfFocusState.citation.substring(0,10), pdfFocusState.pageNumber);
  // };

  const handleToggle = (event, value) => {
    if (!isMobile()) {
      event.preventDefault();
      console.log("new data to analyze", value);
      const source_data = JSON.parse(value.metadata.source_info);
      console.log("source_XXXdata", source_data);
      const documentId = value.metadata.db_document_id;
      const pageNumber = value.metadata.page_number;
      let citation = value.text;
      handleCitationClick(documentId, pageNumber + 1, citation);
    }
  };

  const handleCheckQuote = (event, value) => {
    console.log("toggle trigger");
    if (!isMobile()) {
      console.log("toggle trigger");
      event.preventDefault();
      const currentIndex = props.selectedQuotes.findIndex((selectedDoc) => selectedDoc.text === value.text);
      const newChecked = [...props.selectedQuotes];

      if (currentIndex === -1) {
        newChecked.push(exploredData.filter((selectedDoc) => selectedDoc.text === value.text)[0]);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      props.setSelectedQuotes(newChecked);
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const [isSearchLoading, setIsSearchLoading] = useState(false);

  function createCancelToken() {
    let cancelled = false;

    return {
      cancel() {
        cancelled = true;
      },
      isCancelled() {
        return cancelled;
      },
    };
  }

  let cancelToken = null;

  const handleEnterPress = async () => {
    if (props.isUploadingDocuments) {
      return;
    }
    const snackbarKey = enqueueSnackbar("Querying transactions...");
    setIsSearchLoading(true);

    if (cancelToken) {
      cancelToken.cancel();
      console.log("Cancelled the ongoing task.");
    }

    cancelToken = createCancelToken();

    const exploreData = {
      query: searchQuery,
      limit: 30,
      transaction_ids: transactionsIds,
      query_siblings: isQuerySiblings,
    };

    try {
      await dispatch(exploreTransactions(exploreData, cancelToken));
      if (!cancelToken.isCancelled()) {
        enqueueSnackbar("Done!", { variant: "success" });
      }
    } catch (error) {
      enqueueSnackbar("An error occurred!", { variant: "error" });
    } finally {
      setIsSearchLoading(false);
      closeSnackbar(snackbarKey);
      cancelToken = null; // Reset the cancel token after the task
    }
  };

  const handleSelectAllDocuments = async () => {
    if (exploredData) {
      await dispatch(setFiltredExploredData(exploredData));
    }
  };

  const handleSelectUniqueDocuments = async () => {
    if (exploredData) {
      const highestScorePerFile = exploredData.reduce((acc, doc) => {
        const existing = acc[doc.metadata.db_document_id];
        if (!existing || doc.score > existing.score) {
          acc[doc.metadata.db_document_id] = doc;
        }
        return acc;
      }, {});
      await dispatch(setFiltredExploredData(Object.values(highestScorePerFile)));
    }
    // setFiltredExploredData(Object.values(highestScorePerFile));
  };

  const CustomWidthTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: windowWidth / 3,
    },
  });

  const [toExpandQuotes, setToExpandQuotes] = useState([]);
  const handleExpandQuoteClick = (event, doc) => {
    event.preventDefault();
    setToExpandQuotes((prev) => (prev.includes(doc.text) ? prev.filter((text) => text !== doc.text) : [...prev, doc.text]));
  };

  const [isQuerySiblings, setIsQuerySiblings] = useState(false);
  const handleToggleSiblings = () => {
    setIsQuerySiblings((prev) => !prev);
  };

  return (
    <div className={isMobile() ? " bg-white h-full" : " py-4 bg-white h-[98.5%]"}>
      <div className="mb-2 flex justify-between items-center relative" style={{ gap: "10px" }}>
        <TextareaAutosize
          maxRows={4}
          id="search-bar"
          className="textarea-autosize w-80 border-gray-300 border p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-800 focus:border-transparent pl-8"
          style={{ flex: 1, minWidth: "250px" }}
          placeholder="Ask a question..."
          size="small"
          value={searchQuery}
          onChange={handleSearchChange}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              event.preventDefault();
              handleEnterPress();
            }
          }}
        />
        <div className="absolute top-1 left-0">
          <IconButton size="small">
            <SearchIcon />
          </IconButton>
        </div>
        {/* <Tooltip title="Query Siblings" placement={"top"}>
        <FormControlLabel
          control={<Switch checked={isQuerySiblings} onChange={handleToggleSiblings} />}
        />
        </Tooltip> */}
      </div>

      <div className="flex justify-center pb-2">
        <Button
          size="small"
          variant="outlined"
          onClick={handleSelectAllDocuments}
          style={{
            marginRight: "10px",
            whiteSpace: "nowrap",
            padding: "2px 8px", // Reduced padding for extra small size
            fontSize: "0.6rem", // Reduced font size
          }}
        >
          Show All Documents
        </Button>
        <Button
          size="small"
          variant="outlined"
          onClick={handleSelectUniqueDocuments}
          style={{
            whiteSpace: "nowrap",
            padding: "2px 8px", // Reduced padding for extra small size
            fontSize: "0.6rem", // Reduced font size
          }}
        >
          Show Unique Documents
        </Button>
      </div>

      {isSearchLoading ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "70%" }}>
          <CircularProgress size={48} thickness={4} />
        </div>
      ) : (
        <List className="max-h-[90%] overflow-auto">
          {filteredExploredData ? (
            <>
              {filteredExploredData.map((doc, index) => (
                <React.Fragment key={index}>
                  <ListItem className="hide-scrollbar w-full cursor-pointer" role={undefined} dense style={{ padding: "4px 0px" }}>
                    <div className="flex justify-between w-[130%] items-start">
                      <div className="flex items-center">
                        <>{toExpandQuotes.includes(doc.text) ? <ExpandMoreIcon style={{ color: "gray" }} onClick={(event) => handleExpandQuoteClick(event, doc)} /> : <ChevronRightIcon style={{ color: "gray" }} onClick={(event) => handleExpandQuoteClick(event, doc)} />}</>
                        <InsertDriveFileOutlinedIcon className="mt-0" />
                        <div onClick={(event) => handleToggle(event, doc)} className="flex flex-col ml-2">
                          <span
                            className="line-clamp-2"
                            style={{
                              display: "-webkit-box",
                              WebkitLineClamp: 1,
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {doc.metadata?.filename}
                          </span>
                          {/* <CustomWidthTooltip arrow title={doc.text}> */}
                          <span
                            className="text-sm text-gray-600 line-clamp-2"
                            // sx={{ color: "text.secondary", ...(props.expandedQuote === doc ? {} : { display: "-webkit-box", WebkitLineClamp: 2, WebkitBoxOrient: "vertical", overflow: "hidden", textOverflow: "ellipsis" }) }}
                            style={
                              toExpandQuotes.includes(doc.text)
                                ? { display: "flex" }
                                : {
                                    display: "-webkit-box",
                                    WebkitLineClamp: 2,
                                    WebkitBoxOrient: "vertical",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }
                            }
                          >
                            {doc.text}
                          </span>
                          {/* </CustomWidthTooltip> */}
                        </div>
                      </div>
                      <div style={{ marginLeft: "16px", textAlign: "right" }}>
                        {/* <span style={{ fontSize: "0.875rem", color: "rgba(0, 0, 0, 0.54)" }}>{doc.filetype}</span> */}
                        <span style={{ fontSize: "0.875rem", color: "rgba(0, 0, 0, 0.54)" }}>{"PDF"}</span>
                      </div>
                    </div>
                  </ListItem>
                  {index < filteredExploredData.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </>
          ) : (
            <div className="flex items-center justify-center">
              <span className="text-gray-400 lowercase text-xs">Search a query</span>
            </div>
          )}
        </List>
      )}
    </div>
  );
};

export default DocumentsList;
