import React, { useEffect, useState } from "react";
import { Field, reduxForm } from "redux-form";
import { connect, useSelector } from "react-redux";
import { Grid, TextField, Button } from "@mui/material";
import { windowHeight } from "../../../utils/utils";

const getDisabled = (value) => {
  switch (value) {
    case "Rôle":
      return true;

    case "Name":
      return true;

    case "Prénom":
      return true;

    case "Email":
      return true;

    case "Organization":
      return true;

    case "Position":
      return true;

    default:
      return true;
  }
};

const renderTextField = ({ input, label, meta: { touched, error }, ...custom }) => <TextField variant="outlined" label={label} error={touched && error} helperText={touched && error} {...input} {...custom} />;

// const renderTextField = ({ input, label, type, meta: { touched, error, warning } }) => (
//   <div className="flex justify-start items-center">
//     <div className="w-[140px] h-[50px] flex items-center">
//       <label className="text-sm font-Poppins-Light">{label}</label>
//     </div>
//     <div className="h-full flex items-center">
//       <input
//         disabled={getDisabled(label)}
//         {...input}
//         placeholder={label}
//         type={type}
//         className={"w-[300px] bg-[#F3F5F7] h-[35px] rounded-lg pl-[10px] text-xs font-Poppins-Regular " + (getDisabled(label) ? "text-gray-400" : "")}
//       />
//     </div>
//   </div>
// );

const validate = (values) => {
  const errors = {};
  if (!values.firstName) {
    errors.firstName = "Required";
  }
  if (!values.lastName) {
    errors.lastName = "Required";
  }
  if (!values.email) {
    errors.email = "Required";
  } else if (!/^.+@.+$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  if (!values.age) {
    errors.age = "Required";
  } else if (isNaN(Number(values.age))) {
    errors.age = "Must be a number";
  } else if (Number(values.age) < 18) {
    errors.age = "Sorry, you must be at least 18 years old";
  }
  return errors;
};

let ContactForm = (props) => {
  const { handleSubmit } = props;

  const [changed, setChanged] = useState(false);
  const user = useSelector((state) => state.auth?.user);

  const [formValues, setFormValues] = useState({
    name: user?.name || "",
    email: user?.email || "",
    organization_name: user?.organization_info?.name || "DueDil",
    position: user?.organization_info?.position || "Guest",
  });

  // useEffect(() => {
  //   console.log("Form Values", formValues);
  // }, [formValues]);

  const handleInputChange = (e) => {
    // const { name, value } = e.target;
    // setFormValues({ ...formValues, [name]: value });
    // setChanged(true);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField name="name" variant="outlined" label="Full name" type="text" onChange={handleInputChange} value={formValues.name} disabled={getDisabled("name")} fullWidth />
        </Grid>
        <Grid item xs={12}>
          <TextField name="email" variant="outlined" label="Email" type="text" onChange={handleInputChange} value={formValues.email} disabled={getDisabled("Email")} fullWidth />
        </Grid>
        <Grid item xs={12}>
          <TextField name="organization_name" variant="outlined" label="Organization" type="text" onChange={handleInputChange} value={formValues.organization_name} disabled={getDisabled("Organization")} fullWidth />
        </Grid>
        <Grid item xs={12}>
          <TextField name="position" variant="outlined" label="Position" type="text" onChange={handleInputChange} value={formValues.position} disabled={getDisabled("Position")} fullWidth />
        </Grid>
      </Grid>
      {/* <Grid item xs={12} display="flex" justifyContent="center">
        <Button sx={{ width: "80%", marginTop: windowHeight / 220 }} variant="contained" color="primary" disabled={!changed} type="submit">
          Validate
        </Button>
      </Grid> */}
    </form>
  );
};

export default ContactForm;