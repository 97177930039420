import React, { useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import ImportFormModal from "../screens/DataScreen/components/ImportFormModal";
import { useSelector } from "react-redux";
import NoUploadModal from "./NoUploadModal";
import { backendClient } from "../api/backend";
import { MAX_FILES_UPLOAD } from "../utils/constants";
import { handleDownloadDocument } from "../utils/utils";

const Input = styled("input")({
  display: "none",
});

const FilesUpload = ({ uploadedFiles, setUploadedFiles, is_upload }) => {
  const navigate = useNavigate();
  const [uploading, setUploading] = useState(false);
  // const [uploadedFiles, setUploadedFiles] = useState([]);
  const [filesToRemove, setFilesToRemove] = useState([]);
  const [isModalFormVisible, setIsModalFormVisible] = useState(false);
  const [isVisibleModalNoUpload, setIsVisibleModalNoUpload] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const [isLoadingDeals, setIsLoadingDeals] = useState(true);
  const [totalDeals, setTotalDeals] = useState(null);

  useEffect(() => {
    const fetch_fn = async () => {
      const totalDeals_ = await backendClient.countTransactions();
      setTotalDeals(totalDeals_["count"]);
      setIsLoadingDeals(false);
    };
    fetch_fn();
  }, []);

  const handleFileSelect = (event, isDrag = false) => {
    const files = isDrag ? event : event.target.files;
    const totalFiles = uploadedFiles.length + files.length;

    if (totalFiles > MAX_FILES_UPLOAD) {
      alert(`You cannot upload more than ${MAX_FILES_UPLOAD} files in this test environnement.`);
      return; // Prevent further processing
    }

    const newFiles = Array.from(files).filter((file) => {
      return !uploadedFiles.some((uploadedFile) => uploadedFile.name === file.name);
    });

    if (newFiles.length > 0) {
      setUploadedFiles((prev) => [...prev, ...newFiles]);
    }
  };

  // const handleFileSelect = (event, isDrag) => {
  //   const selectedFiles = isDrag ? event : event?.target.files;

  //   if (selectedFiles) {
  //     // Filter out files that have already been uploaded
  //     const newFiles = Array.from(selectedFiles).filter((file) => {
  //       return !uploadedFiles.some((uploadedFile) => uploadedFile.name === file.name);
  //     });

  //     if (newFiles?.length > 0) {
  //       // Add the newFiles to the uploadedFiles state
  //       setUploadedFiles((prevUploadedFiles) => [...prevUploadedFiles, ...newFiles]);
  //     }
  //   }
  // };

  const handleNoUploadPossible = (event) => {
    setIsVisibleModalNoUpload(true);
  };

  const handleRemoveFile = (index) => {
    const newUploadedFiles = [...uploadedFiles];
    const removedFile = newUploadedFiles.splice(index, 1)[0];
    setUploadedFiles(newUploadedFiles);

    // If the removed file was previously uploaded, mark it for removal on submission
    if (removedFile) {
      setFilesToRemove([...filesToRemove, removedFile]);
    }
  };

  const handleUpload = async () => {
    setIsModalFormVisible(true);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const files = event.dataTransfer.files;
    handleFileSelect(files, true);
  };

  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const handleCloseImportModal = () => {
    if (!isSubmitLoading) {
      setIsModalFormVisible(false);
    }
  };

  return (
    <section className="flex flex-col items-center justify-center p-4 h-60 bg-slate-100 rounded-md" onDragOver={handleDragOver} onDrop={handleDrop}>
      <NoUploadModal open={isVisibleModalNoUpload} handleClose={() => setIsVisibleModalNoUpload(false)} />
      <ImportFormModal isSubmitLoading={isSubmitLoading} setIsSubmitLoading={setIsSubmitLoading} uploadedFiles={uploadedFiles} open={isModalFormVisible} handleClose={handleCloseImportModal} />

      <>
        {user.organization_id === "k-fin" || parseInt(totalDeals) <= 3 ? (
          <>
            {uploading ? (
              <CircularProgress size={48} thickness={4} />
            ) : uploadedFiles?.length === 0 ? (
              <label htmlFor="file-upload-button" className="flex flex-col items-center justify-center h-full mb-2">
                <input accept="application/pdf" multiple id="file-upload-button" type="file" onChange={handleFileSelect} style={{ display: "none" }} />
                <Button
                  variant="outlined"
                  component="span"
                  className="flex items-center py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  // onClick={() => documsent.getElementById("file-upload-button").click()}
                >
                  <CloudUploadIcon className="mr-2" /> Import Files
                </Button>
              </label>
            ) : null}
          </>
        ) : (
          <Button
            onClick={handleNoUploadPossible}
            variant="outlined"
            component="span"
            className="flex items-center py-2 px-4 border border-red-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <CloudUploadIcon className="mr-2" /> Import Files
          </Button>
        )}
      </>

      {uploadedFiles?.length > 0 && (
        <div className="w-[80%]">
          {/* FILES LIST */}
          {/* <p className="mb-2 text-lg font-semibold">Uploaded Files:</p> */}
          <div className="h-32 overflow-y-auto">
            <div className="grid grid-cols-3 gap-1 h-16">
              {uploadedFiles.map((file, index) => (
                <div key={index} className="flex items-center justify-between space-x-2">
                  <span className="truncate flex-grow-0">{file.name}</span>
                  <IconButton onClick={() => handleRemoveFile(index)}>
                    <DeleteIcon />
                  </IconButton>
                </div>
              ))}
            </div>
          </div>
          {/* ADD FILES */}
          {uploadedFiles?.length > 0 && (
            <label htmlFor="file-upload-button-2" className="mb-2">
              <Input multiple accept="*" id="file-upload-button-2" type="file" onChange={handleFileSelect} />
              <Button
                variant="outlined"
                component="span"
                className="flex items-center py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mt-4 mx-auto w-full"
              >
                <CloudUploadIcon className="mr-2" /> Add Files
              </Button>
            </label>
          )}
          {/* UPLOAD */}
          {uploadedFiles?.length > 0 && is_upload && (
            <>
              <label htmlFor="process-button" className="mb-2">
                <Button
                  id="process-button"
                  variant="outlined"
                  component="span"
                  className="flex items-center py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mt-4 mx-auto w-full"
                  onClick={handleUpload}
                >
                  Upload
                </Button>
              </label>
            </>
          )}
        </div>
      )}
    </section>
  );
};

export default FilesUpload;
