// import Fuse from "fuse.js";
// import { DocumentColorEnum, highlightColors } from "./pdfColors";
// // import { DocumentColorEnum, highlightColors } from "./colors";

// const HIGHLIGHT_CLASSNAME = "opacity-40 saturate-[3] highlighted-by-llama ";

// const DIRECTION = {
//   START: 0,
//   END: 1,
// };

// export function scrollToHighlight(pageNumber) {
//   // if (hasScrolled.current) return; // Prevent multiple scrolls
//   const element = document.querySelector(`div[data-page-number='${pageNumber + 1}']`);
//   if (element) {
//     element.scrollIntoView({ behavior: "smooth", block: "start" });
//     console.log("Scrolled to page number:", pageNumber + 1);
//     // hasScrolled.current = true; // Mark as scrolled
//   } else {
//     console.log("Element not found for page number:", pageNumber + 1);
//   }
// }

// export function multiHighlight(textToHighlight, pageNumber, color) {
//   let highlightApplied = false; // Flag to track if highlight is applied

//   if (color === 0) {
//     color = DocumentColorEnum.yellow;
//   }
//   var highlightColor = highlightColors[color];
//   const spans = document.querySelectorAll(`div[data-page-number='${pageNumber + 1}'] .react-pdf__Page__textContent.textLayer span`);

//   console.log("Text to highlight1:", textToHighlight);
//   console.log("Number of spans found:", spans.length);
//   console.log("page number 1 : ",pageNumber+1);

//   function attemptHighlight(searchString) {
//     // console.log("Attempting to highlight with search string:", searchString);

//     const words = [];
//     spans.forEach((span, spanIdx) => {
//       const spanWords = (span.textContent || "").split(" ");
//       spanWords.forEach((text, wordIdx) => {
//         words.push({ text, spanIdx, wordIdx });
//       });
//     });

//     console.log("Words extracted from spans:", words);

//     const searchWords = searchString?.split(" ");
//     const lenSearchString = searchWords?.length;
//     if (!lenSearchString) {
//       console.log("Search string is empty after split.");
//       return false;
//     }

//     const insignificantWords = new Set(["a", "an", "the", "of", "in", "on", "at", "to", "is", "it"]);

//     function getSignificantStartingWord(searchWords) {
//       for (let i = 0; i < searchWords.length; i++) {
//         const word = searchWords[i];
//         if (word.length >= 4 && !insignificantWords.has(word.toLowerCase())) {
//           return word;
//         }
//       }
//       return null;
//     }

//     const firstWord = getSignificantStartingWord(searchWords);
//     if (!firstWord) {
//       console.log("No significant first word found.");
//       return false;
//     }

//     const searchData = generateDirectSearchData(firstWord, words, lenSearchString);
//     console.log("Search data generated for Fuse.js:", searchData);

//     const options = {
//       includeScore: true,
//       threshold: 0.8, // Adjusted threshold
//       minMatchCharLength: 12,
//       shouldSort: true,
//       findAllMatches: true,
//       includeMatches: true,
//       keys: ["text"],
//     };

//     const fuse = new Fuse(searchData, options);
//     const result = fuse.search(searchString);
//     console.log("Fuse.js search result:", result);

//     if (result.length > 0) {
//       const searchResult = result[0].item;

//       const startSpan = searchResult.startSpan || 0;
//       const endSpan = searchResult.endSpan || 0;
//       const startWordIdx = searchResult.startWordIdx || 0;
//       const endWordIdx = searchResult.endWordIdx || 0;

//       console.log("Highlighting spans from", startSpan, "to", endSpan);
//       // scrollToHighlight(pageNumber); // Scroll to the highlighted page

//       for (let i = startSpan; i <= endSpan; i++) {
//         const spanToHighlight = spans[i];
//         if (i === startSpan) {
//           if (startWordIdx === 0) {
//             console.log("Highlighting entire span at index:", i);
//             highlightHtmlElement(spanToHighlight, highlightColor);
//             highlightApplied = true;
//           } else {
//             console.log("Partially highlighting span at index:", i, "from startWordIdx:", startWordIdx);
//             partialHighlight(startWordIdx, spanToHighlight, DIRECTION.START);
//             highlightApplied = true;
//           }
//         } else if (i === endSpan) {
//           if (endWordIdx === 0) {
//             console.log("End word index is 0, returning.");
//             return highlightApplied;
//           } else {
//             console.log("Partially highlighting span at index:", i, "to endWordIdx:", endWordIdx);
//             partialHighlight(endWordIdx, spanToHighlight, DIRECTION.END);
//             highlightApplied = true;
//           }
//         } else {
//           console.log("Highlighting entire span at index:", i);
//           highlightHtmlElement(spanToHighlight, highlightColor);
//           highlightApplied = true;
//         }
//       }
//     } else {
//       console.log("No match found with Fuse.js.");
//     }
//     return highlightApplied;
//   }

//   // Clean and prepare the search string
//   const searchString = textToHighlight
//     ?.replace(/\s{2,}/g, " ")
//     .replace(/\t/g, " ")
//     .trim()
//     .replace(/(\r\n|\n|\r)/g, "");

//   console.log("Cleaned search string:", searchString);

//   // Attempt initial highlight
//   if (attemptHighlight(searchString)) {
//     console.log("Initial highlight successful.");
//     // scrollToHighlight(pageNumber); // Scroll to the highlighted page only if highlighting is successful
//     console.log("we will return true now");
//     return true;
//   }

//   console.log("Initial highlight failed.");

//   return false;
// }

// export function highlightHtmlElement(div, color) {
//   const text = div.textContent || "";
//   const newSpan = document.createElement("span");

//   newSpan.className = HIGHLIGHT_CLASSNAME + color;
//   newSpan.innerText = text;
//   div.innerText = "";
//   div.appendChild(newSpan);
// }

// function partialHighlight(idx, span, direction) {
//   if (direction === 0) {
//     direction = DIRECTION.START;
//   }
//   const text = span.textContent;
//   if (!text) {
//     return;
//   }
//   const test = text.split(" ")[idx - 1] || "";
//   const substringToHighlight = test;

//   span.textContent = "";

//   const parts = text.split(substringToHighlight);

//   parts.forEach((part, index) => {
//     if (direction === DIRECTION.START) {
//       if (index === 0) {
//         span.appendChild(document.createTextNode(part));
//       } else {
//         span.appendChild(document.createTextNode(test));
//         const highlightSpan = document.createElement("span");
//         highlightSpan.className = HIGHLIGHT_CLASSNAME;
//         highlightSpan.textContent = part;
//         span.appendChild(highlightSpan);
//       }
//     }

//     if (direction === DIRECTION.END) {
//       if (index === 0) {
//         const highlightSpan = document.createElement("span");
//         highlightSpan.className = HIGHLIGHT_CLASSNAME;
//         highlightSpan.textContent = part;
//         span.appendChild(highlightSpan);
//         span.appendChild(document.createTextNode(part));
//       } else {
//         span.appendChild(document.createTextNode(test));
//         span.appendChild(document.createTextNode(part));
//       }
//     }
//   });
// }

// function generateDirectSearchData(startString, words, n) {
//   const searchStrings = [];
//   console.groupCollapsed("generating search")
//   console.log("words ",words);
//   console.log("start string",startString)
//   for (let i = 0; i <= words.length - n; i++) {
//     console.log("text ",i, " ", words[i].text)
//     if (words[i].text === startString) {
//       const text = words.slice(i, i + n).reduce((acc, val) => acc + " " + val.text, "");

//       const startSpan = words[i]?.spanIdx || 0;
//       const endSpan = words[i + n - 1]?.spanIdx || 0;
//       const startWordIdx = words[i]?.wordIdx || 0;
//       const endWordIdx = words[i + n - 1]?.wordIdx || 0;

//       searchStrings.push({ text: text.trim(), startSpan: startSpan, endSpan: endSpan, startWordIdx: startWordIdx, endWordIdx: endWordIdx });
//     }
//   }

//   console.log("Generated search data:", searchStrings);
//   console.groupEnd();
//   return searchStrings;
// }

import Fuse from "fuse.js";
import { DocumentColorEnum, highlightColors } from "./pdfColors";

const HIGHLIGHT_CLASSNAME = "highlighted-by-llama";
const OPACITY_CLASS = "opacity-40";
const SATURATION_CLASS = "saturate-[3]";

export function scrollToHighlight(pageNumber) {
  const element = document.querySelector(`div[data-page-number='${pageNumber + 1}']`);
  if (element) {
    element.scrollIntoView({ behavior: "smooth", block: "start" });
    // console.log("Scrolled to page:", pageNumber + 1);
  }
}

export function scrollToHighlightSpan(pageNumber, indexSpan) {
  const spans = document.querySelectorAll(`div[data-page-number="${pageNumber}"] .react-pdf__Page__textContent.textLayer span`);
  if (spans.length === 0 || !spans) {
    return;
  }
  if (indexSpan) {
    const span = spans[indexSpan];
    if (span) {
      span.scrollIntoView({ behavior: "smooth", block: "start" });
    } else {
      const element = document.querySelector(`div[data-page-number='${pageNumber}']`);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  } else {
    const element = document.querySelector(`div[data-page-number='${pageNumber}']`);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }
}

export function highlightText(container, textToHighlight, color) {
  const options = {
    includeScore: true,
    threshold: 0.45, // Lower threshold to catch more potential matches
    keys: ["text"],
    findAllMatches: true,
    minMatchCharLength: 3,
    includeMatches: true,
  };

  const textElements = Array.from(container.querySelectorAll("span"));
  const items = textElements.map((el, index) => ({
    index,
    text: el.textContent || "",
  }));

  // console.log(items);
  // console.log(textToHighlight);
  if (textToHighlight === undefined) return false;

  const fuse = new Fuse(items, options);
  const results = fuse.search(textToHighlight);
  console.log(results);
  if (results.length === 0) {
    return false;
  }
  // Apply highlights
  results.forEach(({ item, matches }) => {
    const element = textElements[item.index];
    element.style.backgroundColor = "yellow" || color; // Apply color directly
    element.className += ` ${HIGHLIGHT_CLASSNAME} ${OPACITY_CLASS} ${SATURATION_CLASS}`;
    console.log(`Highlighted: "${element.textContent}"`);
  });

  return results.length > 0;
}

export function multiHighlight(textToHighlight, pageNumber, colorCode) {
  const color = highlightColors[colorCode] || highlightColors[DocumentColorEnum.yellow];
  const pageContainer = document.querySelector(`div[data-page-number='${pageNumber + 1}'] .react-pdf__Page__textContent.textLayer`);

  // console.log("Text to highlight:", textToHighlight);
  // console.log("Color:", color);

  if (!pageContainer) {
    // console.log("No page container found for:", pageNumber + 1);
    return false;
  }

  const isHighlighted = highlightText(pageContainer, textToHighlight, color);
  console.log(isHighlighted);

  if (isHighlighted !== false) {
    // console.log("Highlighting successful on page:", pageNumber + 1);
    scrollToHighlightSpan(pageNumber + 1, 0);
    // scrollToHighlight(pageNumber);
    return true;
  } else {
    console.log("No matches found on page:", pageNumber + 1);
    return false;
  }
}

export function setupHighlighting(query, pageNumber) {
  const textToHighlight = query.replace(/\s{2,}/g, " ").trim();

  const color = DocumentColorEnum.yellow; // Example: Setting a default color
  multiHighlight(textToHighlight, pageNumber, color);
}
