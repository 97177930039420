import React, { useEffect, useState } from "react";
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TableSortLabel, TablePagination, Checkbox, InputAdornment, IconButton, TextField, Tooltip, Grid, Box } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import KeyIcon from "@mui/icons-material/Key"; // Represents 'keyword pair'
import CircularProgress from "@mui/material/CircularProgress";
import NavigationBar from "../../components/NavigationBar";
import { formatDate, getIndustryColor, isMobile, windowHeight } from "../../utils/utils";
import { useNavigate } from "react-router-dom";
import BackButton from "../../components/BackButton";
import { useDispatch, useSelector } from "react-redux";
// import { fetchDocuments } from "../../redux/actions/documentsAction";
import AddKeywordPairsFormModal from "./components/AddKeywordPairsFormModal";
import { fetchKeywords, deleteKeywords, getSynchronized, startSynchronize, updateKeywordSynchronized } from "../../redux/actions/keywordAction";
import DeleteConfirmModal from "../../components/DeleteConfirmModal";
import LightbulbIcon from "@mui/icons-material/Lightbulb"; // Represents 'hypothesis'
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import { api_subscription_key, backendUrl } from "../../config";
import { useSnackbar } from "notistack";
import { store } from "../../redux/store";
import { colors } from "../../utils/colors";
import { backendClient } from "../../api/backend";

const KeywordPairsScreen = () => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [orderDirection, setOrderDirection] = useState("asc");
  const [valueToOrderBy, setValueToOrderBy] = useState("title");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isModalAddKeywordPairVisible, setIsModalAddKeywordPairVisible] = useState(false);
  const [isDeleteModalVisisble, setIsDeleteModalVisisble] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [keyword_, setKeyword] = useState(null);
  const [isModalEditVisible, setIsModalEditVisible] = useState(false);

  const dispatch = useDispatch();
  const open = useSelector((state) => state.navBar.is_open);
  const user = useSelector((state) => state.auth.user);

  const keywords = useSelector((state) => state.keywords.keywords);
  const { enqueueSnackbar } = useSnackbar();

  const [isLoadingKeywords, setIsLoadingKeywords] = useState(false);
  const [totalKeywords, setTotalKeywords] = useState(0);
  const fetch_fn = async () => {
    setIsLoadingKeywords(true);
    await dispatch(fetchKeywords(page + 1, rowsPerPage));
    if (totalKeywords === 0) {
      const totalKeywords_ = await backendClient.countKeywords();
      setTotalKeywords(totalKeywords_["count"]);
    }
    setIsLoadingKeywords(false);
  };
  useEffect(() => {
    fetch_fn();
  }, [dispatch, page, rowsPerPage]);

  const [synchronize, setSynchronize] = useState({ synchronized: keywords.filter((x) => !x.synchronized).length > 0 ? false : true });

  const [synchronizeStatus, setSynchronizeStatus] = useState("All synchronized");

  useEffect(() => {
    const getSynchronized_fn = async () => {
      const synchronize = await dispatch(getSynchronized());
      console.log("synchronizeXXX", synchronize);
      if (synchronize.is_loading) {
        setSynchronizeStatus("Synchronizing...");
      }
      setSynchronize(synchronize);
    };
    getSynchronized_fn();
  }, []);

  // SYNCHRONIZE SSE
  useEffect(() => {
    console.log("synchronizeXX", synchronize);
    if (synchronize.synchronized && keywords.length > 0) {
      const NewController = new AbortController();
      const { signal } = NewController;

      const url = `${backendUrl}api/keyword/events_synchronize/`;
      console.log("SSE_url", url);

      const state = store.getState();
      const token = state.auth.accessToken;
      const headers = {
        Authorization: `Bearer ${token}`,
        "Ocp-Apim-Subscription-Key": api_subscription_key,
      };

      fetchEventSource(url, {
        method: "GET",
        signal: signal,
        headers,
        onmessage: async (event) => {
          console.log("EVENT.DATA", event.data);
          if (event.data !== "ping" && event.data !== '{"status": "complete"}') {
            const parsedData = JSON.parse(event.data);
            console.log("parsedData", parsedData);
            // {"keyword_id": keyword.id, "synchronized": True}
            enqueueSnackbar("Keyword " + parsedData.id + " built!", { variant: "success" });
            //update keyword in redux
            await dispatch(updateKeywordSynchronized(parsedData.id, parsedData.synchronized));
          } else if (event.data === '{"status": "complete"}') {
            console.log("aborting connection");
            NewController.abort();
            // setIsUploadingDocuments(false);
          }
        },
        onopen: async () => {
          console.log("Connection to the server opened.");
        },
        onerror: (error) => {
          console.error("EventSource failed:", error);
          NewController.abort();
        },
        onclose: () => {
          console.log("onClose Triggered");
          NewController.abort();
        },
      });

      // Cleanup function to abort the fetchEventSource when the component unmounts
      return () => {
        NewController.abort();
      };
    }
  }, [dispatch, synchronize]);

  const handleSynchronize = async () => {
    if (isLoadingKeywords || isLoadingDelete) {
      return;
    }
    console.log("Synchronize Hypothesis action triggered");
    // navigate("hypothesis");
    await dispatch(startSynchronize());
    setSynchronize({ synchronized: true });
  };

  const handleStartConversation = () => {
    console.log("Start Conversation action triggered");
  };

  const handleRequestSort = (property) => {
    const isAscending = valueToOrderBy === property && orderDirection === "asc";
    setValueToOrderBy(property);
    setOrderDirection(isAscending ? "desc" : "asc");
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = keywords.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex >= 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(0);
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchQuery(value.toLowerCase()); // Convert the search query to lowercase for case-insensitive comparison
  };
  // Filter keywords based on the search query
  const filteredKeywords = keywords.filter((keyword) => {
    // Check if the search query is included in any of the keyword fields
    return keyword.name.toLowerCase().includes(searchQuery) || keyword.id.toLowerCase().includes(searchQuery) || keyword.industry.toLowerCase().includes(searchQuery) || keyword.example.toLowerCase().includes(searchQuery);
  });

  const handleAddKeywordPair = async () => {
    if (isLoadingKeywords || isLoadingDelete) {
      return;
    }
    setIsModalAddKeywordPairVisible(true);
  };

  const handleDeleteKeyword = async () => {
    if (isLoadingKeywords || isLoadingDelete) {
      return;
    }
    setIsDeleteModalVisisble(true);
  };

  const handleConfirmDelete = async () => {
    setIsLoadingDelete(true);
    await dispatch(deleteKeywords(selected));
    await fetch_fn();
    setSelected([]);
    setIsDeleteModalVisisble(false);
    setIsLoadingDelete(false);
    enqueueSnackbar("Keyord deleted!", { variant: "success" });
  };

  const handleCancelDelete = async () => {
    setIsDeleteModalVisisble(false);
  };

  const handleEdit = (event, keyword) => {
    event.stopPropagation();
    if (isLoadingKeywords || isLoadingDelete) {
      return;
    }
    setKeyword(keyword);
    setIsModalEditVisible(true);
  };

  const handleDelete = (event, id) => {
    console.log("Delete action triggered for:", id);
  };

  const handleMoreActions = (id) => {
    console.log("More actions triggered for:", id);
  };

  return (
    <Grid container sx={{ height: "100vh" }}>
      <Grid item xs={12} md={open ? 9 : 12} lg={open ? 10 : 12} sx={{ transition: "margin 0.5s", marginLeft: open ? "240px" : "75px" }}>
        <Grid sx={{ p: 2, display: "flex", justifyContent: "center", alignItems: "center", bgcolor: "gray.100" }}>
          <Grid sx={{ p: 2, bgcolor: colors.WHITE, boxShadow: 3, borderRadius: 2, width: "100%", height: "100%" }}>
            {/* MODALS */}
            <DeleteConfirmModal isLoadingDelete={isLoadingDelete} type={"Keyword Pairs"} open={isDeleteModalVisisble} handleClose={handleCancelDelete} handleConfirm={handleConfirmDelete} />
            <AddKeywordPairsFormModal setSynchronize={setSynchronize} open={isModalAddKeywordPairVisible} setOpen={setIsModalAddKeywordPairVisible} />
            {isModalEditVisible && <AddKeywordPairsFormModal keyword={keyword_} open={isModalEditVisible} setOpen={setIsModalEditVisible} />}

            {/* HEADER */}
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2, pb: 2, borderBottom: 1, borderColor: "divider" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <h2 className="text-xl font-bold text-gray-800">Keyword Pairs</h2>
              </Box>
              {!isMobile() && (
                <Box sx={{}}>
                  <Button variant={synchronize.synchronized ? "outlined" : "contained"} color="primary" disabled={true} onClick={handleSynchronize} className="mr-2">
                    {!synchronize.synchronized ? "Synchronize" : synchronizeStatus}
                  </Button>
                </Box>
              )}
            </Box>

            {isMobile() && (
              <Box sx={{ display: "flex", marginBottom: windowHeight / 400 }}>
                <Button
                  sx={{
                    flexGrow: 1,
                    mr: 1, // marginRight: 10px replaced by material ui spacing system
                    whiteSpace: "nowrap",
                    fontSize: "0.75rem",
                  }}
                  variant={synchronize.synchronized ? "outlined" : "contained"}
                  color="primary"
                  disabled={true}
                  onClick={handleSynchronize}
                >
                  {!synchronize.synchronized ? "Synchronize" : synchronizeStatus}
                </Button>
              </Box>
            )}

            {!isMobile() ? (
              <Box sx={{ display: "flex", justifyContent: "between", alignItems: "center", mb: 4 }}>
                <TextField
                  id="search-bar"
                  className="flex-grow"
                  variant="outlined"
                  placeholder="Search by keyword..."
                  size="small"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                {selected.length > 0 && (
                  <Button variant={selected.length === 0 ? "outlined" : "contained"} onClick={handleDeleteKeyword} style={{ marginLeft: 16, backgroundColor: selected.length === 0 ? "" : "red" }}>
                    {selected.length <= 1 ? "Delete keyword pair" : "Delete keyword pair"}
                  </Button>
                )}
                <Button variant={"contained"} color="primary" onClick={handleAddKeywordPair} style={{ marginLeft: 16 }}>
                  {"Add keyword pair"}
                </Button>
              </Box>
            ) : (
              <Box sx={{ display: "flex", flexDirection: "column", mb: 4 }}>
                <TextField
                  id="search-bar"
                  className="flex-grow"
                  variant="outlined"
                  placeholder="Search by keyword..."
                  size="small"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <Box sx={{ display: "flex", marginTop: windowHeight / 600 }}>
                  {selected.length > 0 && (
                    <Button sx={{ flexGrow: 1, whiteSpace: "nowrap", fontSize: "0.75rem", mr: 1, backgroundColor: selected.length === 0 ? "" : "red" }} variant={selected.length === 0 ? "outlined" : "contained"} onClick={handleDeleteKeyword}>
                      {selected.length <= 1 ? "Delete keyword pair" : "Delete keyword pair"}
                    </Button>
                  )}
                  <Button sx={{ flexGrow: 1, whiteSpace: "nowrap", fontSize: "0.75rem" }} variant={"contained"} color="primary" onClick={handleAddKeywordPair}>
                    {"Add keyword pair"}
                  </Button>
                </Box>
              </Box>
            )}

            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox indeterminate={selected.length > 0 && selected.length < keywords.length} checked={keywords.length > 0 && selected.length === keywords.length} onChange={handleSelectAllClick} inputProps={{ "aria-label": "select all keywords" }} />
                    </TableCell>
                    <TableCell>
                      {/* <TableSortLabel active={valueToOrderBy === "name"} direction={valueToOrderBy === "industry" ? orderDirection : "asc"} onClick={() => handleRequestSort("industry")}> */}
                      Name
                      {/* </TableSortLabel> */}
                    </TableCell>
                    <TableCell>
                      {/* <TableSortLabel active={valueToOrderBy === "industry"} direction={valueToOrderBy === "industry" ? orderDirection : "asc"} onClick={() => handleRequestSort("industry")}> */}
                      Industry
                      {/* </TableSortLabel> */}
                    </TableCell>
                    <TableCell>
                      {/* <TableSortLabel active={valueToOrderBy === "relationship"} direction={valueToOrderBy === "country" ? orderDirection : "asc"} onClick={() => handleRequestSort("country")}> */}
                      Is Key
                      {/* </TableSortLabel> */}
                    </TableCell>
                    <TableCell>Example</TableCell>
                    <TableCell>Synchronized</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>

                {isLoadingKeywords ? (
                  <TableRow>
                    <TableCell colSpan={7} style={{ textAlign: "center" }}>
                      <CircularProgress size={48} thickness={4} />
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {filteredKeywords.length > 0 ? (
                      <TableBody>
                        {filteredKeywords.map((keyword) => {
                          const isItemSelected = selected.indexOf(keyword.id) !== -1;
                          const labelId = `enhanced-table-checkbox-${keyword.id}`;

                          return (
                            <TableRow hover onClick={(event) => handleClick(event, keyword.id)} role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={keyword.id} selected={isItemSelected} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                              <TableCell padding="checkbox">
                                <Checkbox checked={isItemSelected} inputProps={{ "aria-labelledby": labelId }} />
                              </TableCell>
                              <TableCell component="th" scope="row" id={labelId} className="w-40">
                                <div className="font-bold truncate">{keyword.name}</div>
                                <div className="truncate">{keyword.id}</div>
                              </TableCell>
                              <TableCell>
                                <span
                                  className="inline-block px-4 py-2 rounded-full text-white truncate max-w-xs"
                                  style={{
                                    backgroundColor: getIndustryColor(keyword.industry),
                                  }}
                                >
                                  {keyword.industry}
                                </span>
                              </TableCell>
                              <TableCell className="w-[4%]">{keyword.is_key ? "Yes" : "No"}</TableCell>
                              <TableCell className="w-[12%]">{keyword.example}</TableCell>
                              {/* <TableCell>{formatDate(keyword.date)}</TableCell> */}

                              <TableCell component="th" scope="row" id={labelId} className="w-40">
                                <div className="flex items-center space-x-2">{keyword.synchronized ? <CheckIcon style={{ color: "green" }} /> : <CloseIcon style={{ color: "red" }} />}</div>
                              </TableCell>

                              <TableCell>
                                <Tooltip title="Edit">
                                  <IconButton onClick={(event) => handleEdit(event, keyword)}>
                                    <EditIcon />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    ) : (
                      <TableRow>
                        <TableCell colSpan={7} style={{ textAlign: "center" }}>
                          <div>
                            <KeyIcon style={{ fontSize: 40, color: "lightgray" }} /> {/* Import ChatIcon from '@mui/icons-material' */}
                            <div>No keywords created yet.</div>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={handleAddKeywordPair} // Implement this function
                            >
                              Create a Keyword
                            </Button>
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                )}
              </Table>
              <TablePagination rowsPerPageOptions={[5, 10, 25]} component="div" count={totalKeywords} rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} />
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default KeywordPairsScreen;
