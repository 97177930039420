import { combineReducers } from "redux";
import navBarReducer from "./navBarReducer";
import transactionReducer from "./transactionReducer";
import documentsReducer from "./documentsReducer";
import conversationReducer from "./conversationReducer";
import authReducer from "./authReducer";
import keywordReducer from "./keywordReducer";
import exploreReducer from "./exploreReducer";
import snackbarReducer from "./snackBarReducer";
import hypothesisReducer from "./hypothesisReducer";
// import other reducers

export default combineReducers({
  navBar: navBarReducer,
  transactions: transactionReducer,
  documents: documentsReducer,
  conversation: conversationReducer,
  auth: authReducer,
  keywords: keywordReducer,
  explore: exploreReducer,
  snackbar: snackbarReducer,
  hypothesis: hypothesisReducer,
  // other reducers
});
