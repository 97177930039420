import React, { useState } from "react";
import { Modal, Box, Typography, Button } from "@mui/material";
import FilesUpload from "./FilesUpload";

function AddDocumentModal({ uploadedFiles, setUploadedFiles, isLoadingAddDocument, open, handleConfirm, handleClose }) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "90%", sm: "80%", md: "70%", lg: "50%", xl: "50%" }, // Responsive width
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
  };

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {"Add documents"}
        </Typography>

        {/* File Upload Section */}
        <Box
          sx={{
            mt: 2,
          }}
        >
          <FilesUpload uploadedFiles={uploadedFiles} setUploadedFiles={setUploadedFiles} is_upload={false} />
        </Box>

        {/* Buttons Section */}
        <Box
          sx={{
            mt: 2,
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Button
            disabled={isLoadingAddDocument}
            // disabled={false}
            variant="contained"
            color="primary"
            onClick={() => {
              handleConfirm();
            }}
          >
            {isLoadingAddDocument ? "Adding document..." : "Confirm"}
          </Button>
          <Button disabled={isLoadingAddDocument} variant="outlined" color="primary" onClick={handleClose}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default AddDocumentModal;
