// keywordActions.js
import { backendClient } from "../../api/backend";
import { ADD_KEY_HYPOTHESIS, CLEAR_HYPOTHESIS, DELETE_HYPOTHESIS, EXTRACT_HYPOTHESIS, EXTRACT_ONE_HYPOTHESIS, FETCH_HYPOTHESIS } from "./types";

export const extractHypothesis = (transactionsIds, user_id, reset = false) => {
  return async (dispatch) => {
    const hypothesis = await backendClient.extractHypothesis(transactionsIds, user_id, reset);
    dispatch({
      type: EXTRACT_HYPOTHESIS,
    });
  };
};
export const reExtractOneHypothesis = (transaction_id, user_id, hypothesis_id) => {
  return async (dispatch) => {
    const hypothesis = await backendClient.reExtractOneHypothesis(transaction_id, user_id, hypothesis_id);
    console.log('hypothesis', hypothesis)
    dispatch({
      type: EXTRACT_ONE_HYPOTHESIS,
      payload: hypothesis,
    });
  };
};

export const fetchHypothesis = (transaction_id) => {
  return async (dispatch) => {
    let hypothesis = await backendClient.fetchHypothesis(transaction_id);
    if (hypothesis.message === "No hypotheses found for this transaction") {
      hypothesis = [];
    }
    console.log("XXX...hypothesis", hypothesis);
    dispatch({
      type: FETCH_HYPOTHESIS,
      payload: hypothesis,
    });
  };
};

export const clearHypothesis = () => {
  return async (dispatch) => {
    dispatch({
      type: CLEAR_HYPOTHESIS,
    });
  };
};

export const addKeyHypothesis = (key_hypothesis) => {
  return async (dispatch) => {
    dispatch({
      type: ADD_KEY_HYPOTHESIS,
      payload: key_hypothesis,
    });
  };
};

export const deleteHypothesis = (hypothesisId) => {
  return async (dispatch) => {
    const response = await backendClient.deleteHypothesis([hypothesisId]);
    console.log('hypothesisIds', hypothesisId)
    console.log('responseXX', response)
    dispatch({
      type: DELETE_HYPOTHESIS,
      payload: hypothesisId,
    });
  };
};
