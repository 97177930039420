import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Paper, Box, Grid } from "@mui/material";
import Chat from "./component/Chat";
import DisplayMultiplePdfs from "../../components/pdf-viewer/DisplayMultiplePdfs";
import { PdfFocusProvider } from "../../components/pdf-viewer/pdfContext";
import { useDispatch, useSelector } from "react-redux";
import { isMobile, windowHeight } from "../../utils/utils";
import { faq_chat_answer, faq_chat_question } from "../../utils/constants";
import FAQModal from "../../components/FAQModal";

const ChatScreen = () => {
  const { conversationId } = useParams();
  const open = useSelector((state) => state.navBar.is_open);
  const conversation = useSelector((state) => state.conversation.conversation);
  console.log("newest conversation", conversation);
  const isShowPdf = () => {
    const cond = conversation && conversation.messages.length > 0;
    return cond;
  };
  const is_show_pdf = isShowPdf();

  const [openFAQModel, setOpenFAQModel] = useState(true);
  // Check local storage to see if the modal should be shown
  useEffect(() => {
    const showModal = localStorage.getItem("showFAQModal_chat") !== "false";
    setOpenFAQModel(showModal);
  }, []);

  return (
    <PdfFocusProvider>
      <Grid className="bg-gray-100 h-full" container sx={{ height: "100vh" }}>
        <Grid item xs={12} md={open ? 9 : 12} lg={open ? 10 : 12} sx={{ transition: "margin 0.5s", marginLeft: open ? "240px" : "75px" }}>
          <Grid sx={{ p: 2, display: "flex", justifyContent: "center", alignItems: "center", bgcolor: "gray.100", height: "100%" }}>
            <Grid item xs={12} md={is_show_pdf ? 6 : 12} style={{ height: "95vh" }}>
              <Chat conversationId={conversationId} is_show_pdf={is_show_pdf} />
            </Grid>

            {/* <span onClick={() => {
              console.log('toto', conversation)
            }}>
              DEBUG
            </span> */}

            <FAQModal type={"chat"} question={faq_chat_question} answer={faq_chat_answer} open={openFAQModel} setOpen={setOpenFAQModel} />

            {is_show_pdf && !isMobile() && (
              <Grid item xs={12} md={6} style={{ height: "95vh" }}>
                <Paper elevation={2} className="flex flex-grow h-full">
                  <Box style={{ flexGrow: 1, display: "flex", justifyContent: "center", alignItems: "center", height: "100%", marginTop: windowHeight / 120 }}>
                    <DisplayMultiplePdfs is_conversation={true} documentsIds={conversation.documentsIds} pdfWidth={open ? 250 : 70} pdfHeight={75} />
                  </Box>
                </Paper>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </PdfFocusProvider>
  );
};

export default ChatScreen;
