import { CLEAR_TRANSACTIONS, CREATE_TRANSACTION, SET_TRANSACTIONS, DELETE_TRANSACTIONS, FETCH_TRANSACTION, FETCH_TRANSACTIONS, REMOVE_TRANSACTION_DOCUMENT_ID, SET_TRANSACTION, SET_TRANSACTION_ID, UPDATE_TRANSACTION_DOCUMENTS_IDS, UPDATE_TRANSACTION_PROPERTY, UPDATE_TRANSACTION_DOCUMENT_UPLOADED } from "../actions/types";

const initialState = {
  transactionId: null, // used for explore
  transaction: null,
  transactions: [], // Store the list of transactions here
};

const transactionReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_TRANSACTION:
      return {
        ...state,
        transaction: action.payload,
        transactions: [action.payload],
      };

    case SET_TRANSACTION:
      return {
        ...state,
        transaction: action.payload,
      };
    case SET_TRANSACTIONS:
      return {
        ...state,
        transactions: action.payload,
      };

    case SET_TRANSACTION_ID:
      return {
        ...state,
        transactionId: action.payload,
      };

    case UPDATE_TRANSACTION_DOCUMENTS_IDS:
      return {
        ...state,
        transactions: state.transactions.map((transaction) => (transaction.id === action.transaction_id ? { ...transaction, ...action.payload } : transaction)),
      };

    case DELETE_TRANSACTIONS:
      return {
        ...state,
        transaction: state.transaction && action.payload.includes(state.transaction.id) ? null : state.transaction,
        transactions: state.transactions.filter((t) => !action.payload.includes(t.id)),
      };

    case UPDATE_TRANSACTION_PROPERTY:
      const updatedTransactions = state.transactions.map((trans) => (trans.id === action.payload.id ? action.payload : trans));
      return {
        ...state,
        transactions: updatedTransactions,
      };

    case REMOVE_TRANSACTION_DOCUMENT_ID:
      return {
        ...state,
        transactions: state.transactions.map((transaction) => ({
          ...transaction,
          documents_ids: transaction.documents_ids.filter((docId) => docId !== action.payload),
        })),
      };

    case CLEAR_TRANSACTIONS:
      return {
        ...state,
        transactions: [],
      };

    case FETCH_TRANSACTIONS:
      return {
        ...state,
        transactions: action.payload,
      };

    case UPDATE_TRANSACTION_DOCUMENT_UPLOADED:
      return {
        ...state,
        transaction: {
          ...state.transaction,
          document_uploaded: action.payload,
        },
      };

    case FETCH_TRANSACTION:
      return {
        ...state,
        transaction: action.payload.transaction,
        transactions: action.payload.add_to_transactions ? [...state.transactions, action.payload.transaction] : state.transactions,
      };

    default:
      return state;
  }
};

export default transactionReducer;
