import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, Grid, ButtonGroup, Button, CircularProgress } from "@mui/material";
import { windowHeight, windowWidth } from "../../../utils/utils";
import { colors } from "../../../utils/colors";
import { backendClient } from "../../../api/backend";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { getSynchronized } from "../../../redux/actions/keywordAction";
import { useDispatch, useSelector } from "react-redux";

export const SummaryCard = ({ title, value, isLoading }) => {
  const titleStyle = {
    fontSize: "16px",
    color: colors.GRAY_ONE,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    // fontWeight: 500,
  };

  const valueStyle = {
    fontSize: "28px",
    color: colors.BLACK,
    fontWeight: "semibold",
  };
  return (
    <Card sx={{ height: windowHeight / 8, borderRadius: 2, boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px" }}>
      <CardContent>
        <Typography
          sx={{
            fontSize: "16px",
            color: colors.GRAY_ONE,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          color="text.secondary"
          gutterBottom
        >
          {title}
        </Typography>
        {isLoading ? (
          <Box sx={{ marginTop: windowHeight / 500 }}>
            <CircularProgress size={24} />
          </Box>
        ) : (
          <Typography sx={{ fontSize: "28px", color: colors.BLACK, fontWeight: "semibold" }} variant="h5" component="div">
            {value}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export const SynchronizedCard = ({ synchronized, isLoading }) => {
  const syncStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: synchronized ? "#4CAF50" : "#F44336",
    fontSize: "16px", // Adjust based on your design needs
    fontWeight: "bold",
  };

  return (
    <Card sx={{ borderRadius: 2, boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <CardContent>
        {isLoading ? (
          <CircularProgress size={24} />
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: synchronized ? "#4CAF50" : "#F44336",
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            {synchronized ? (
              <>
                <CheckCircleOutlineIcon sx={{ marginRight: "8px" }} />
                Synchronized
              </>
            ) : (
              <>
                <HighlightOffIcon sx={{ marginRight: "8px" }} />
                Not Synchronized
              </>
            )}
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

const DealsSummary = () => {
  const dispatch = useDispatch();

  const [isLoadingTotalHypothesis, setIsLoadingTotalHypothesis] = useState(true);
  const [isLoadingTotalKeyKeywords, setIsLoadingTotalKeyKeywords] = useState(true);
  const [isLoadingTotalKeywords, setIsLoadingTotalKeywords] = useState(true);
  const [isLoadingSynchronize, setIsLoadingSynchronize] = useState(true);

  const [totalHypothesis, setTotalHypothesis] = useState(0);
  const [totalKeyKeywords, setTotalKeyKeywords] = useState(0);
  const [totalKeywords, setTotalKeywords] = useState(0);
  const [synchronize, setSynchronize] = useState(null);

  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    const fetch_fn = async () => {
      try {
        setIsLoadingTotalHypothesis(true);
        const totalHypothesis_ = await backendClient.countHypothesis();
        setTotalHypothesis(totalHypothesis_["count"]);
        setIsLoadingTotalHypothesis(false);

        setIsLoadingTotalKeywords(true);
        const totalKeywords_ = await backendClient.countKeywords();
        setTotalKeywords(totalKeywords_["count"]);
        setIsLoadingTotalKeywords(false);

        setIsLoadingTotalKeyKeywords(true);
        const totalKeyKeywords_ = await backendClient.countKeyKeywords();
        setTotalKeyKeywords(totalKeyKeywords_["count"]);
        setIsLoadingTotalKeyKeywords(false);

        setIsLoadingSynchronize(true);
        const synchronize_ = await dispatch(getSynchronized());
        setSynchronize(synchronize_);
        setIsLoadingSynchronize(false);
      } catch (error) {
        console.error("Error loading data:", error);
        // Optionally handle setting loading false here if needed
      }
    };
    fetch_fn();
  }, [user]);

  return (
    <>
      <h2 className="text-xl font-bold text-gray-800 pb-4">Hypothesis summary</h2>

      <Grid container spacing={2} sx={{ backgroundColor: "", marginTop: -windowHeight / 800 }}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <SynchronizedCard synchronized={synchronize?.synchronized} isLoading={isLoadingSynchronize} />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <SummaryCard title="Total keywords" value={totalKeywords} isLoading={isLoadingTotalKeywords} />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <SummaryCard title="Total hypothesis" value={totalHypothesis} isLoading={isLoadingTotalHypothesis} />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <SummaryCard title="Total key hypothesis" value={totalKeyKeywords} isLoading={isLoadingTotalKeyKeywords} />
        </Grid>
      </Grid>
    </>
  );
};

export default DealsSummary;
