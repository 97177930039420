import React, { useEffect, useState } from "react";
import { Typography, Grid, Card, CardContent } from "@mui/material";
import { convertToSectorPerformanceData, isMobile, windowHeight, windowWidth } from "../../../utils/utils";
import { ResponsiveBar } from "@nivo/bar";
import { backendClient } from "../../../api/backend";
import { useSelector } from "react-redux";

const SectorPerformance = () => {
  // const sectorPerformanceData = [
  //   {
  //     sector: "Oil",
  //     valuation: 20,
  //   },
  //   {
  //     sector: "Fiber",
  //     valuation: 20,
  //   },
  //   {
  //     sector: "Wind Offshore",
  //     valuation: 80,
  //   },
  // ];

  const [sectorPerformanceData, setSectorPerformanceData] = useState([]);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    const fetch_fn = async () => {
      const industryCount_ = await backendClient.valueCountTransaction("industry");
      console.log("industryCount_", industryCount_);
      setSectorPerformanceData(convertToSectorPerformanceData(industryCount_));
    };
    fetch_fn();
  }, [user]);

  return (
    <>
      <Grid item xs={12}>
        <h2 className="text-xl font-bold text-gray-800">Sector Performance</h2>
        <Typography variant="subtitle1" gutterBottom component="div" sx={{ mb: -1 }}>
          Allocation - sector sheet
        </Typography>
      </Grid>

      <Grid item xs={12} style={{ backgroundColor: "transparent", height: "100%", width: isMobile() ? windowWidth : windowWidth / 1.2 }}>
        <ResponsiveBar
          data={sectorPerformanceData}
          keys={["Transactions"]}
          indexBy="sector"
          margin={{ top: 50, right: isMobile() ? 0 : 120, bottom: isMobile() ? windowHeight / 10 : 50, left: isMobile() ? windowWidth / 7 : 60 }}
          padding={0.3}
          valueScale={{ type: "linear" }}
          indexScale={{ type: "band", round: true }}
          colors={{ scheme: "nivo" }}
          borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "sector",
            legendPosition: "middle",
            legendOffset: 32,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Transactions",
            legendPosition: "middle",
            legendOffset: -40,
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          legends={[
            {
              dataFrom: "keys",
              anchor: isMobile() ? "bottom" : "bottom-right",
              direction: "column",
              justify: false,
              translateX: isMobile() ? 0 : 120,
              translateY: isMobile() ? windowHeight / 10 : 0,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: "left-to-right",
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                {
                  on: "hover",
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
          animate={true}
          motionStiffness={90}
          motionDamping={15}
        />
      </Grid>
    </>
  );
};

export default SectorPerformance;
